<span
  class="procure-button-wrapper"
  [class.procure-button-wrapper--has-prefix]="!!prefix"
  [class.procure-button-wrapper--has-suffix]="!!suffix"
>
  <mat-icon *ngIf="prefix">{{ prefix }}</mat-icon>
  <ng-content></ng-content>
  <mat-icon *ngIf="suffix">{{ suffix }}</mat-icon>
  <span *ngIf="loading" class="button-spinner-wrapper">
    <mat-spinner [diameter]="16"></mat-spinner>
  </span>
</span>
<div
  matRipple
  class="procure-button-ripple"
  [class.mat-mdc-button-ripple-round]="isIconButton"
  [matRippleDisabled]="true"
  [matRippleCentered]="isIconButton"
  [matRippleTrigger]="elementRef.nativeElement"
></div>
<div class="mat-button-focus-overlay"></div>
