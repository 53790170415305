import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { CanBlockNavigation } from './interfaces';
import { RouteNavigationService } from './route-navigation.service';

@Directive()
export abstract class AbstractRouteComponent implements OnInit, OnDestroy, CanBlockNavigation {
  constructor(
    protected routeNavigationService: RouteNavigationService,
  ) {}

  /** @inheritdoc */
  public ngOnInit(): void {
    this.routeNavigationService.register(this);
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.routeNavigationService.unregister(this);
  }

  /** @inheritdoc */
  public abstract canDeactivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | boolean;

  /** @inheritdoc */
  public abstract canUnload(): boolean;
}
