import { DestroyRef, Injectable, inject } from '@angular/core';
import { BaseApiService } from '../libs/api';
import { ErrorHandlerService } from '../libs/application-insights';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected readonly baseApiService = inject(BaseApiService);
  protected readonly errorHandlerService = inject(ErrorHandlerService);
  protected readonly destroyRef = inject(DestroyRef);
}
