import { HttpParams } from '@angular/common/http';
import { JsonObject, JsonValue } from '@angular-devkit/core';
import { CustomHttpUrlEncodingCodec } from './custom-http-url-encoding-codec';

/**
 * The operation identifier for `CustomHttpParams.clone`.
 */
const enum Operation {
  APPEND,
  SET,
  DELETE,
}

/**
 * @TODO Check this Angular Bug concerning HttpUrlEncoding : https://github.com/angular/angular/issues/18261
 *
 * An HTTP request/response body that represents serialized parameters,
 * per the MIME type `application/x-www-form-urlencoded`.
 *
 * This class is immutable - all mutation operations return a new instance.
 */
export class CustomHttpParams /* implements HttpParams */ {
  private map: Map<string, string>;

  private encoder: CustomHttpUrlEncodingCodec = new CustomHttpUrlEncodingCodec();

  constructor(params: Record<string, unknown> | Map<string, string> = {}) {
    if (params instanceof Map) {
      this.map = new Map(params);
    } else {
      const map = new Map();
      const jsonObject = this.toJsonValue(params) as JsonObject;
      Object
        .keys(jsonObject)
        .forEach((key) => {
          this.parseJsonValueIntoMap(jsonObject[key], key, map);
        });
      this.map = map;
    }
  }

  /**
   * Check whether the body has one or more values for the given parameter name.
   */
  public has(param: string): boolean {
    return this.map.has(param);
  }

  /**
   * Get the first value for the given parameter name, or `undefined` if it's not present.
   */
  public get(param: string): string | undefined {
    const res = this.map.get(param);

    return res != null ? res : undefined;
  }

  /**
   * Get all the parameter names for this body.
   */
  public keys(): string[] {
    return Array.from(this.map.keys());
  }

  /**
   * Construct a new body with an appended value for the given parameter name.
   */
  public append(param: string, value: unknown): CustomHttpParams {
    if (value == null) {
      return this.delete(param);
    }

    return this.clone(param, this.toJsonValue(value), Operation.APPEND);
  }

  /**
   * Construct a new body with a new value for the given parameter name.
   */
  public set(param: string, value: unknown): CustomHttpParams {
    if (value == null) {
      return this.delete(param);
    }

    return this.clone(param, this.toJsonValue(value), Operation.SET);
  }

  /**
   * Construct a new body with all values for the given parameter removed.
   */
  public delete(param: string): CustomHttpParams {
    return this.clone(param, undefined, Operation.DELETE);
  }

  /**
   * Construct a `HttpParams` object with all the current parameters.
   */
  public toHttpParams(): HttpParams {
    const dictionary: Record<string, string> = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of this.map.entries()) {
      if (value != null) {
        dictionary[key] = value;
      }
    }

    return new HttpParams({
      fromObject: dictionary,
      encoder: new CustomHttpUrlEncodingCodec(),
    });
  }

  /**
   * Serialize the body to an encoded string, where key-value pairs (separated by `=`) are
   * separated by `&`s.
   */
  public toString(): string {
    const params: string[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of this.map.entries()) {
      if (value != null) {
        params.push(`${this.encoder.encodeKey(key)}=${this.encoder.encodeValue(value)}`);
      }
    }

    return params.join('&');
  }

  /**
   * Returns a clone of the object according to the operation provided.
   */
  private clone(param: string, value: JsonValue | undefined, operation: Operation): CustomHttpParams {
    const map: Map<string, string> = new Map();
    let paramSet = false;

    if (operation !== Operation.APPEND) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, existingValue] of this.map.entries()) {
        // Check if key and param might be the same
        if (key.indexOf(param) === 0) {
          const nextChar = key.substring(param.length, param.length + 1);
          const isExactParamMatch = ['', '['].includes(nextChar);

          // eslint-disable-next-line max-depth
          if (!isExactParamMatch) {
            // If key and param are not an exact match, just add the existing key-value pair
            map.set(key, existingValue);
          } else if (operation === Operation.SET && !paramSet) {
            // On the first call, replace existing key with param.
            // Remove all matches on subsequent calls (skipped).
            // e.g. `param` will be set and `param[second]` will be ignored.
            paramSet = true;
            this.parseJsonValueIntoMap(value as JsonValue, param, map);
          }
          // else:
          //   operation == DELETE : just skip setting the param
          //   operation == SET and param was already set : skip to remove obsolete params
        } else {
          // If key and param are not the same, just add the existing key-value pair
          map.set(key, existingValue);
        }
      }
    }

    if (
      operation === Operation.APPEND
      || (operation === Operation.SET && !paramSet)
    ) {
      this.parseJsonValueIntoMap(value as JsonValue, param, map);
    }

    return new CustomHttpParams(map);
  }

  /**
   * Converts an object to basic JSON values.
   */
  private toJsonValue(obj: unknown): JsonValue {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Called recursively to construct HTTP param key-value pairs from nested objects.
   */
  private parseJsonValueIntoMap(obj: JsonValue, prefix: string, httpParamsMap: Map<string, string>): void {
    if (obj == null || typeof obj === 'function') {
      return;
    }

    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i += 1) {
        this.parseJsonValueIntoMap(obj[i], `${prefix}[${i}]`, httpParamsMap);
      }
    } else if (typeof obj !== 'object') {
      // This is primitive value (string, number, boolean)
      httpParamsMap.set(prefix, `${obj}`);
    } else {
      // This is an object
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          this.parseJsonValueIntoMap(obj[key], `${prefix}[${key}]`, httpParamsMap);
        }
      }
    }
  }
}
