import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccessResponse } from '../types/access-response.type';
import { ApiService } from './api.service';
import { CustomHttpParams } from '../libs/api';

@Injectable({
  providedIn: 'root',
})
export class GlobalAccessApiService extends ApiService {
  public checkAccess$(entryId: string, token: string): Observable<AccessResponse | null> {
    const params = new CustomHttpParams({ token });

    return this.baseApiService.post$<AccessResponse, null>(`Access/${entryId}/Access`, AccessResponse, null, params)
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error, {
            text: 'There was an issue while checking the access token.',
          });

          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }
}
