import { coerceBoolean } from 'coerce-property';
import {
  Component, Input, HostBinding, ChangeDetectionStrategy, OnChanges,
} from '@angular/core';
import { EntityIdAliasPrefixes, EntityUtility } from '../../utilities/entity.utility';
import { NgChanges } from '../../utilities/angular-types.utility';
import { BadgeComponent } from '../badge/badge.component';

const colors: Record<EntityIdAliasPrefixes, string> = {
  PROJ: '#ff6c25',
  RFRFQ: '#00acb6',
  RFQ: '#0096ea',
  QUOT: '#6d1541',
  PREQ: '#eece23',
  PEX: '#d90000',
  PE: '#0a8a15',
  TPROJ: '#991f00',
  TRFRFQ: '#00667a',
  SUP: 'transparent',
};

@Component({
  selector: 'procure-entity-badge',
  templateUrl: './entity-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BadgeComponent],
})
export class EntityBadgeComponent implements OnChanges {
  @HostBinding('class')
  @Input()
  public hostClass: string = '';

  @Input()
  public label: string | null = '';

  @Input()
  public clickable: boolean = false;

  @Input()
  public link?: string;

  @Input() @coerceBoolean public openInNewTab: boolean | '' = false;

  @Input() @coerceBoolean public inverted: boolean | '' = false;

  public type: EntityIdAliasPrefixes = EntityIdAliasPrefixes.Project;

  public get class(): string { return 'entity-badge'; }

  public get backgroundColor(): string {
    return colors[this.type] || 'transparent';
  }

  public ngOnChanges(changes: NgChanges<EntityBadgeComponent>): void {
    if ('label' in changes && changes.label.currentValue !== changes.label.previousValue) {
      if (!changes.label.currentValue) {
        return;
      }

      const type = EntityUtility.getType(changes.label.currentValue);

      this.type = type || EntityIdAliasPrefixes.Project;
    }
  }
}
