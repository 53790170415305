import {
  HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  catchError, filter, take, map,
} from 'rxjs/operators';
import { Either, isRight } from 'fp-ts/Either';
import {
  Errors, TypeC, ArrayC, IntersectionC, UnionC, Type,
} from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import { CustomHttpErrorResponse } from '../helpers/custom-http-error-response';
import { IoTsTypeError } from '../helpers/io-ts-type-error';
import { CustomHttpParams } from '../helpers/custom-http-params';

const formatStatus = (statusCode: number, statusText: string): string => `${statusCode > 0 ? `${statusCode} ` : ''}${statusText}`;

/**
 * HTTP defines a set of request methods to indicate the desired action to be performed for a given resource.
 */
export type HttpRequestMethod = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH';

const catchHttpError = (error: HttpErrorResponse): Observable<never> => {
  let statusCode: number = error.status;
  const { statusText } = error;
  const allErrors: string[] = [];

  if (error.error) {
    const errorResponse = error.error;

    if (typeof errorResponse === 'string') {
      allErrors.push(errorResponse);
    } else if (typeof errorResponse === 'object') {
      if ('statusCode' in errorResponse && 'message' in errorResponse) {
        /* eslint-disable max-depth */
        if (errorResponse.statusCode) {
          statusCode = errorResponse.statusCode;
        }

        allErrors.push(errorResponse.message);
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const errorProperty in errorResponse) {
          if (Object.prototype.hasOwnProperty.call(errorResponse, errorProperty)) {
            const errors = errorResponse[errorProperty];

            if (Array.isArray(errors)) {
              allErrors.push(...errors);
            }
          }
        }
        /* eslint-enable max-depth */
      }
    }
  }

  const customStatusText = formatStatus(statusCode, statusText);
  const errorMessage = allErrors.join(' \n');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return throwError(new CustomHttpErrorResponse(customStatusText, errorMessage, error as any));
};

export abstract class HttpApi {
  public static readonly version: string = 'v1';

  constructor(private httpClient: HttpClient) {}

  /**
   * Constructs a GET request.
   */
  public get$<Response>(
    pathName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ioTsType: Type<any> | TypeC<any> | ArrayC<any> | IntersectionC<any>,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<Response | null> {
    const request$ = this.request$<Response>('GET', pathName, null, queryParameters, headers);

    return this.getHttpResponseBody$<Response>(request$, ioTsType);
  }

  /**
   * Constructs a POST request.
   */
  public post$<Response, Request>(
    pathName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ioTsType: Type<any> | TypeC<any> | ArrayC<any> | IntersectionC<any> | UnionC<any>,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<Response | null> {
    const request$ = this.request$<Response>('POST', pathName, body, queryParameters, headers);

    return this.getHttpResponseBody$<Response>(request$, ioTsType);
  }

  /**
   * Constructs a PUT request
   */
  public put$<Response, Request>(
    pathName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ioTsType: Type<any> | TypeC<any> | ArrayC<any> | IntersectionC<any>,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<Response | null> {
    const request$ = this.request$<Response>('PUT', pathName, body, queryParameters, headers);

    return this.getHttpResponseBody$<Response>(request$, ioTsType);
  }

  /**
   * Constructs a DELETE request
   */
  public delete$<Response, Request>(
    pathName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ioTsType: Type<any> | TypeC<any> | ArrayC<any> | IntersectionC<any>,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<Response | null> {
    const request$ = this.request$<Response>('DELETE', pathName, body, queryParameters, headers);

    return this.getHttpResponseBody$<Response>(request$, ioTsType);
  }

  /**
   * Fetches details for a blob download.
   */
  public downloadBlob$(
    pathName: string,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<string | Blob> {
    const params = queryParameters ? queryParameters.toHttpParams() : {};

    return this.httpClient
      .get(this.getEndpointUrl(pathName), {
        responseType: 'blob',
        headers,
        params,
      })
      .pipe(catchError(catchHttpError));
  }

  /**
   * Returns the response body of a HttpEvent sequence.
   */
  private getHttpResponseBody$<Response>(
    observable$: Observable<HttpEvent<Response>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ioTsType?: Type<any> | TypeC<any> | ArrayC<any> | IntersectionC<any> | UnionC<any>,
  ): Observable<Response | null> {
    return observable$.pipe(
      filter((event): event is HttpResponse<Response> => event.type === HttpEventType.Response),
      take(1),
      map((response) => response.body),
      map((response) => {
        if (ioTsType) {
          const res = ioTsType.decode(response);

          if (this.hasErrorByRuntime(res)) {
            return null;
          }

          if (isRight(res)) {
            return res.right;
          }
        }

        return response;
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private hasErrorByRuntime(decodeRes: Either<Errors, any>): boolean {
    const errors = PathReporter.report(decodeRes);

    if (Array.isArray(errors)) {
      if (errors[0] === 'No errors!') {
        return false;
      }

      let parsedErrors: string[] = [];

      errors.forEach((error) => {
        const components = error.split('/');
        const key = components.join(' => ');

        parsedErrors = [...parsedErrors, key];
      });

      throw new IoTsTypeError('io-ts type error', parsedErrors.join('\n'));
    }

    return true;
  }

  /**
   * Constructs a HTTP request.
   */
  private request$<Response>(
    method: HttpRequestMethod,
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<HttpEvent<Response>> {
    const params = queryParameters ? queryParameters.toHttpParams() : {};
    const request$ = this.httpClient.request<Response>(method, this.getEndpointUrl(url), {
      body,
      headers,
      reportProgress: true,
      observe: 'events',
      params,
    });

    return request$.pipe(catchError(catchHttpError));
  }

  /**
   * Builds the final URL depending on the url input
   *
   * @param url string which is used in the final url
   */
  protected abstract getEndpointUrl(url: string): string;
}
