import { coerceBoolean } from 'coerce-property';
import {
  Component, Input, HostBinding, ChangeDetectionStrategy,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { BADGE_BACKGROUND_COLOR, BADGE_COLOR } from '../../constants';

@Component({
  selector: 'procure-badge',
  templateUrl: './badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RouterLink],
})
export class BadgeComponent {
  public static nextId: number = 0;

  @Input()
  public id: string;

  @HostBinding('class')
  @Input()
  public hostClass: string = '';

  @Input()
  public label: string | null = null;

  @Input()
  public link: string | null = null;

  @Input()
  public color: string = BADGE_COLOR;

  @Input()
  public backgroundColor: string = BADGE_BACKGROUND_COLOR;

  @Input()
  public borderColor: string | null = null;

  @Input() @coerceBoolean public openInNewTab: boolean | '' = false;

  @Input() @coerceBoolean public inverted: boolean | '' = false;

  public get style(): string {
    const color = this.color || this.inverted ? `color: ${this.inverted ? this.backgroundColor : this.color};` : '';
    const backgroundColor = !this.inverted && this.backgroundColor ? `background-color: ${this.backgroundColor};` : '';
    const borderColor = `border-color: ${this.backgroundColor};`;

    return `${color} ${backgroundColor} ${borderColor}`;
  }

  public get class(): string { return 'badge'; }

  constructor() {
    this.id = `badge-${BadgeComponent.nextId}`;
    BadgeComponent.nextId += 1;
  }
}
