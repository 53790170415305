import * as t from 'io-ts';
import { nullable } from '../io-ts/nullable';
import { UserId } from './user-id.type';

const User = t.intersection([
  UserId,
  t.type({
    name: nullable(t.string),
    isActive: t.boolean,
  }),
], 'User');

type User = t.TypeOf<typeof User>;
export { User };
