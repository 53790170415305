<ng-container *ngIf="!link">
  <div
    data-testid="badge-without-link"
    [class]="class"
    [style]="style"
    [id]="id"
  >
    {{ label }}
  </div>
</ng-container>
<ng-container *ngIf="link">
  <a
    *ngIf="!openInNewTab"
    data-testid="badge-with-internal-link"
    [routerLink]="link"
    [class]="class"
    [style]="style"
    [id]="id"
  >
    {{ label }}
  </a>
  <a
    *ngIf="openInNewTab"
    target="_blank"
    rel="noopener noreferrer"
    data-testid="badge-with-external-link"
    [href]="link"
    [class]="class"
    [style]="style"
    [id]="id"
  >
    {{ label }}
  </a>
</ng-container>
