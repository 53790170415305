import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

/**
 * Differentiate snackbar purpose.
 */
export enum SnackbarType {
  INFO = 1,
  SUCCESS = 2,
  ERROR = 3,
}

export interface SnackbarData {
  readonly title?: string;
  readonly text?: string;
  readonly type?: SnackbarType;
}

@Component({
  templateUrl: './snackbar.component.html',
  standalone: true,
  imports: [NgIf, MatIconModule],
})
export class SnackbarComponent {
  public get icon(): string | undefined {
    switch (this.data.type) {
      case (SnackbarType.INFO):
        return 'info';
      case (SnackbarType.ERROR):
        return 'error';
      case (SnackbarType.SUCCESS):
        return 'check_circle';
      default:
        return undefined;
    }
  }

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: SnackbarData,
  ) { }
}
