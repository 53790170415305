import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private locale: string;

  constructor() {
    this.locale = navigator.language;
    this.initializeLocale(this.locale);
  }

  public getLocale(): string {
    return this.locale;
  }

  private initializeLocale(locale: string): Promise<void> {
    return this.importLocale(locale).catch(
      () => this.importLocale(locale.split('-')[0]),
    );
  }

  private importLocale(locale: string): Promise<void> {
    return import(`@/../@angular/common/locales/${locale}.mjs`).then((fallbackLocaleModule) => {
      registerLocaleData(fallbackLocaleModule.default, locale);
      this.locale = locale;
    });
  }
}
