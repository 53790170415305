import { Component } from '@angular/core';
import { PageContentComponent } from '../../components/page-content/page-content.component';
import { ExpansionPanelComponent } from '../../libs/core';

@Component({
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [ExpansionPanelComponent, PageContentComponent],
})
export class NotFoundComponent {}
