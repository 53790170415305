import { Snippet } from '@microsoft/applicationinsights-web';
import { IChannelControls, ITelemetryPlugin } from '@microsoft/applicationinsights-core-js';
import { DistributedTracingModes } from '@microsoft/applicationinsights-common';

type AppInsightsConfig = Snippet['config'];

export class AngularApplicationInsightsConfig implements AppInsightsConfig {
  public disableAngularRouteTracking?: boolean;

  public instrumentationKey?: string;

  public connectionString?: string;

  public diagnosticLogInterval?: number;

  public maxMessageLimit?: number;

  public loggingLevelConsole?: number;

  public loggingLevelTelemetry?: number;

  public enableDebugExceptions?: boolean;

  public endpointUrl?: string;

  public extensionConfig?: Record<string, unknown>;

  public extensions?: ITelemetryPlugin[];

  public channels?: IChannelControls[][];

  public emitLineDelimitedJson?: boolean;

  public accountId?: string;

  public sessionRenewalMs?: number;

  public sessionExpirationMs?: number;

  public maxBatchSizeInBytes?: number;

  public maxBatchInterval?: number;

  public enableDebug?: boolean;

  public disableExceptionTracking?: boolean;

  public disableTelemetry?: boolean;

  public samplingPercentage?: number;

  public autoTrackPageVisitTime?: boolean;

  public enableAutoRouteTracking?: boolean;

  public disableAjaxTracking?: boolean;

  public disableFetchTracking?: boolean;

  public overridePageViewDuration?: boolean;

  public maxAjaxCallsPerView?: number;

  public disableDataLossAnalysis?: boolean;

  public disableCorrelationHeaders?: boolean;

  public distributedTracingMode?: DistributedTracingModes;

  public correlationHeaderExcludedDomains?: string[];

  public disableFlushOnBeforeUnload?: boolean;

  public disableFlushOnUnload?: boolean;

  public enableSessionStorageBuffer?: boolean;

  public isCookieUseDisabled?: boolean;

  public cookieDomain?: string;

  public isRetryDisabled?: boolean;

  public url?: string;

  public isStorageUseDisabled?: boolean;

  public isBeaconApiDisabled?: boolean;

  public sdkExtension?: string;

  public isBrowserLinkTrackingEnabled?: boolean;

  public appId?: string;

  public enableCorsCorrelation?: boolean;

  public namePrefix?: string;

  public enableRequestHeaderTracking?: boolean;

  public enableResponseHeaderTracking?: boolean;

  public enableAjaxErrorStatusText?: boolean;

  public onunloadDisableBeacon?: boolean;
}
