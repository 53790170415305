import {
  ChangeDetectionStrategy, Component, Input, OnChanges,
} from '@angular/core';
import { NgChanges } from '../../utilities/angular-types.utility';

/**
 * File icons are available in an icon pack: https://fileicons.org/
 */
@Component({
  selector: 'procure-file-icon',
  templateUrl: './file-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FileIconComponent implements OnChanges {
  @Input()
  public extension?: string;

  private _iconUrl: string | null = null;

  public get iconUrl(): string | null { return this._iconUrl; }

  public ngOnChanges(changes: NgChanges<FileIconComponent>): void {
    if ('extension' in changes && changes.extension?.previousValue !== changes.extension?.currentValue) {
      this._iconUrl = `/assets/icons/${this.detectIconUrl()}.svg`;
    }
  }

  private detectIconUrl(): string {
    if (!this.extension) {
      return 'blank';
    }

    const mapping = Object.entries({
      xlsx: ['xlm', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx'],
      docx: ['doc', 'docb', 'docm', 'docx', 'dot', 'dotm', 'dotx'],
      pptx: ['pps', 'ppsx', 'ppt', 'pptm', 'pptx'],
      jpg: ['jpg', 'jpeg'],
      tiff: ['tif', 'tiff'],
      wmf: ['wmf', 'wmz'],
      pdf: ['pdf'],
      zip: ['zip'],
      png: ['png'],
      gif: ['gif'],
      tmp: ['tmp'],
      msg: ['msg'],
      eml: ['eml'],
      rtf: ['rtf'],
      rar: ['rar'],
      html: ['html', 'htm'],
      mp4: ['mp4'],
      rfc: ['rfc822'],
    }).reduce((acc, [key, values]) => {
      values.forEach((value) => {
        acc[value] = key;
      });

      return acc;
    }, {} as Record<string, string>);

    return mapping[this.extension.toLowerCase()] || 'blank';
  }
}
