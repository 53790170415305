import {
  ErrorHandler, Inject, Injectable, Injector,
} from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';
import { ENVIRONMENT_CONFIG, EnvironmentConfig, SnackbarService } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environmentConfig: EnvironmentConfig,
    private readonly injector: Injector,
    private readonly appInsightsService: ApplicationInsightsService,
  ) {
    super();
  }

  /**
   * Display an error notification and track the error through Application
   * Insights.
   */
  public handleError(error: Error): void {
    super.handleError(error);

    this.appInsightsService.trackException({ exception: error });

    if (!this.environmentConfig.production) {
      const snackbarService = this.injector.get(SnackbarService);

      snackbarService.showErrorSnackbar({
        title: `Unhandled exception${error.name !== 'Error' ? `: ${error.name}` : ''}`,
        text: error.message,
      });
    }
  }
}
