import {
  Component, Inject, ChangeDetectionStrategy, OnInit, DestroyRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { escapeDialog } from '../../utilities/dialog.utility';
import { ButtonComponent } from '../button/button.component';

/**
 * Differentiate dialog purpose between informative and action prompting dialogs.
 */
export enum DialogType {
  INFO,
  ERROR,
  PROMPT,
}

export interface DialogData {
  readonly type: DialogType;
  readonly title: string;
  readonly text: string;
  readonly info?: string;
  readonly blackout?: boolean;
  readonly primaryAction?: string;
  readonly secondaryAction?: string;
  readonly closeCallback?: () => void;
  readonly confirmationCallback?: () => Observable<boolean | void>;
}

@Component({
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    MatIconModule,
    ButtonComponent,
  ],
})
export class DialogComponent implements OnInit {
  public isConfirming: boolean = false;

  public readonly dialogType: typeof DialogType = DialogType;

  private readonly destroyed$: Subject<void> = new Subject();

  public get type(): DialogType { return this.data.type; }

  public get title(): string { return this.data.title; }

  public get text(): string { return this.data.text; }

  public get info(): string | undefined { return this.data.info; }

  public get blackout(): boolean | undefined { return this.data.blackout; }

  public get primaryAction(): string | undefined { return this.data.primaryAction; }

  public get secondaryAction(): string | undefined { return this.data.secondaryAction; }

  constructor(
    private readonly dialogRef: MatDialogRef<DialogComponent>,
    private readonly componentRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) private readonly data: DialogData,
  ) {
    if (data.blackout) {
      dialogRef.disableClose = true;
    }
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    if (this.blackout) {
      return;
    }

    escapeDialog(this.dialogRef, this.componentRef, () => {
      if (this.type !== DialogType.PROMPT) {
        this.data.closeCallback?.();
      }

      this.dialogRef.close(this.type !== DialogType.PROMPT);
    });
  }

  /**
   * Confirm prompt dialogs and execute the confirmation callback. After
   * execution, the dialog is closed.
   */
  public confirm(): void {
    if (this.isConfirming) {
      return;
    }

    this.isConfirming = true;

    this.getConfirmCallback$()
      .pipe(take(1))
      .pipe(takeUntilDestroyed(this.componentRef))
      .subscribe((successful?: boolean) => {
        this.isConfirming = false;
        this.dialogRef.close((successful !== false));
      });
  }

  public close(): void {
    if (this.blackout) {
      return;
    }

    this.data.closeCallback?.();
  }

  private getConfirmCallback$(): Observable<boolean | void> {
    if (this.data.confirmationCallback != null) {
      return this.data.confirmationCallback();
    }

    // Default callback
    return of(true);
  }
}
