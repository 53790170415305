import { Injectable } from '@angular/core';
import {
  Observable, catchError, of, take,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { string as ioString } from 'io-ts';
import { UploadSettings } from '../types/upload-settings.type';
import { DocumentFileModel } from '../models/document-file.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService extends ApiService {
  public getUploadSettings$(entryId: string): Observable<UploadSettings | null> {
    return this.baseApiService.get$<UploadSettings>(`Document/${entryId}/Settings`, UploadSettings)
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error, {
            text: 'There was an error retrieving upload settings.',
          });

          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  public getFileDownloadUrl$(entryId: string, documentId: string): Observable<string | null> {
    return this.baseApiService.get$<string>(`Document/${entryId}/Download/${documentId}`, ioString)
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error, {
            text: 'There was an error retrieving the document download URL.',
          });

          return of(null);
        }),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  public getPackageDownloadUrl$(entryId: string, files: DocumentFileModel[]): Observable<string | null> {
    return this.baseApiService.post$<string, string[]>(`Document/${entryId}/Download`, ioString, files.map((file) => file.id))
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error, {
            text: 'There was an issue downloading the documents.',
          });

          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  public getPdfDownloadUrl$(entryId: string): Observable<string | null> {
    return this.baseApiService.post$<string, null>(`Document/${entryId}/PDF`, ioString)
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error, {
            text: 'There was an issue downloading the PDF export.',
          });

          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }
}
