import * as t from 'io-ts';
import { nullable } from '../io-ts/nullable';

const UserId = t.type({
  id: t.number,
  email: nullable(t.string),
}, 'UserId');

type UserId = t.TypeOf<typeof UserId>;
export { UserId };
