import { DestroyRef, Injectable, inject } from '@angular/core';
import {
  Action, Selector, State, StateContext,
} from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LookupData } from '../types/lookup-data.type';
import { FetchLookupData, FetchUploadSettings } from '../actions';
import { LookupDataApiService } from '../services/lookup-data-api.service';
import { UploadSettings } from '../types/upload-settings.type';
import { DocumentApiService } from '../services/document-api.service';
import { IdCodeName } from '../libs/core';

export type LookupDataStateShape = {
  data: LookupData | null | undefined;
  uploadSettings: UploadSettings | null | undefined;
}

@Injectable({
  providedIn: 'root',
})
@State<LookupDataStateShape>({
  name: 'lookupData',
  defaults: {
    data: undefined,
    uploadSettings: undefined,
  },
})
export class LookupDataState {
  private readonly lookupDataApiService = inject(LookupDataApiService);
  private readonly documentApiService = inject(DocumentApiService);
  private readonly destroyRef = inject(DestroyRef);

  @Selector()
  public static data(state: LookupDataStateShape): LookupData | null | undefined {
    return state.data;
  }

  @Selector()
  public static currencies(state: LookupDataStateShape): IdCodeName[] | null | undefined {
    return state.data?.currencies;
  }

  @Selector()
  public static uploadSettings(state: LookupDataStateShape): UploadSettings | null | undefined {
    return state.uploadSettings;
  }

  @Action(FetchLookupData)
  public fetchLookupData({ patchState }: StateContext<LookupDataStateShape>): void {
    this.lookupDataApiService.fetchLookupData$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        patchState({
          data: response,
        });
      });
  }

  @Action(FetchUploadSettings)
  public fetchUploadSettings(
    { patchState }: StateContext<LookupDataStateShape>,
    { entryId }: FetchUploadSettings,
  ): void {
    this.documentApiService.getUploadSettings$(entryId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        patchState({
          uploadSettings: response,
        });
      });
  }
}
