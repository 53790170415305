import { coerceBoolean } from 'coerce-property';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'procure-number-badge',
  templateUrl: './number-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NumberBadgeComponent {
  @Input()
  public value: number = 0;

  @Input() @coerceBoolean public clickable: boolean | '' = true;
}
