import {
  Inject, LOCALE_ID, Pipe, PipeTransform,
} from '@angular/core';
import { formatNumber } from '@angular/common';

/**
 * Transforms a string into a number (if needed).
 */
const strToNumber = (value: number | string): number => {
  if (value === '-') {
    return 0;
  }

  if (typeof value === 'string' && !Number.isNaN(Number(value) - parseFloat(value))) {
    return Number(value);
  }

  if (typeof value !== 'number') {
    throw new Error(`${value} is not a number`);
  }

  return value;
};

@Pipe({
  name: 'decimal',
  standalone: true,
})
export class DecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  /**
   * Transforms the input value.
   */
  public transform(value: string | number | null | undefined, decimalDigits?: number): string | null {
    if (value == null) {
      return '-';
    }

    if (!value) {
      return '0';
    }

    try {
      const num = strToNumber(value);
      const maxFactionDigits = (decimalDigits != null ? strToNumber(decimalDigits) : 2);
      const digitsInfo = `1.0-${maxFactionDigits}`;

      return formatNumber(num, this.locale, digitsInfo);
    } catch (error) {
      return '';
    }
  }
}
