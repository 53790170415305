import { InjectionToken } from '@angular/core';

export const DEFAULT_RETURN_URL = '/';
export const DEBOUNCE_INPUT_CHANGE_TIME_SMALL: number = 200;

export const DECIMAL_PATTERN: string = '^(-?\\d+\\.?\\d*)?$';

export const MAX_LOGIN_ATTEMPTS = 3;

export const COMPANY_NAME = new InjectionToken<string>('COMPANY_NAME');
export const DEFAULT_DATE = new Date(1901, 0, 1, 0, 0, 0);
