import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GlobalAccessState } from '../store/global-access.state';

export const authGuard: CanActivateFn = (route) => {
  const store = inject(Store);
  const router = inject(Router);

  const id = route.params.entryId;

  const statuses = store.selectSnapshot(GlobalAccessState.statuses);
  const status = statuses[id];

  if (status !== undefined) {
    return true;
  }

  const { entryId } = route.params;

  return router.createUrlTree(['/', entryId]);
};
