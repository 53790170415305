import {
  Directive, ElementRef, HostListener, Input, Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[numericInput]',
  standalone: true,
})
export class NumericInputDirective {
  @Input()
  public readonly decimal: boolean = false;

  @Input()
  public readonly formControl!: FormControl;

  @Input()
  public readonly decimalSeparator: string = '.';

  private readonly negativeSign: string = '-';

  private readonly inputElement: HTMLInputElement;

  private hasDecimals: boolean = false;

  private hasNegativeSign: boolean = false;

  private readonly navigationKeys: readonly string[] = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  constructor(
    public readonly element: ElementRef,
    private renderer: Renderer2,
  ) {
    this.inputElement = element.nativeElement;
  }

  /**
   * Handle keydown events.
   */
  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line complexity
  public onKeyDown(e: KeyboardEvent): void {
    this.checkDecimals();
    this.checkNegativeSign();

    if (
      // Allow: navigation keys: backspace, delete, arrows etc.
      this.navigationKeys.indexOf(e.key) > -1
      // Allow: actions
      || this.isAction(e)
      // Allow: only one decimal separator
      || (this.decimal && e.key === this.decimalSeparator && !this.hasDecimals)
      // Allow: only one negative sign
      || (e.key === this.negativeSign && !this.hasNegativeSign)
    ) {
      return;
    }

    if (e.key === ' ' || Number.isNaN(Number(e.key))) {
      e.preventDefault();
    } else if (this.inputElement.value === '0' && e.key !== this.decimalSeparator && e.key !== this.negativeSign) {
      this.renderer.setProperty(this.inputElement, 'value', '');
    }
  }

  /**
   * Handle paste events.
   */
  @HostListener('paste', ['$event'])
  public onPaste(e: ClipboardEvent): void {
    e.preventDefault();

    let data = e.clipboardData?.getData('text').replace(/[^0-9.]/g, '');

    if (data?.includes(this.decimalSeparator) && (!this.decimal || this.hasDecimals)) {
      data = data.replace(/\D+/g, '');
    }

    const newNegativeSign = data?.includes(this.negativeSign);

    if (data && newNegativeSign) {
      data = data.replace(new RegExp(this.negativeSign, 'g'), '');
    }

    if (Number.isNaN(Number(data))) {
      return;
    }

    this.insertAtCursor(data || '');

    if (newNegativeSign && this.hasNegativeSign) {
      this.renderer.setProperty(this.inputElement, 'value', `-${this.inputElement.value}`);
    }

    this.formControl?.setValue(this.inputElement.value, { emitEvent: true });
  }

  /**
   * Handle blur events.
   */
  @HostListener('blur')
  public onBlur(): void {
    const { value } = this.inputElement;

    if (value.endsWith(this.decimalSeparator)) {
      this.renderer.setProperty(this.inputElement, 'value', `${value}0`);
    } else if (value.startsWith(this.decimalSeparator)) {
      this.renderer.setProperty(this.inputElement, 'value', `0${value}`);
    }
  }

  /**
   * Correctly insert pasted data after validation
   */
  private insertAtCursor(data: string): void {
    if (this.inputElement.selectionStart || this.inputElement.selectionStart === 0) {
      const startPos = this.inputElement.selectionStart;
      const endPos = this.inputElement.selectionEnd;
      this.inputElement.value = this.inputElement.value.substring(0, startPos)
        + data
        + this.inputElement.value.substring(endPos || startPos, this.inputElement.value.length);
    } else {
      this.renderer.setProperty(this.inputElement, 'value', this.inputElement.value + data);
    }
  }

  private checkDecimals(): void {
    if (!this.decimal) {
      return;
    }

    this.hasDecimals = !!(this.inputElement.value?.includes(this.decimalSeparator));
  }

  private checkNegativeSign(): void {
    this.hasNegativeSign = !!(this.inputElement.value?.includes(this.negativeSign));
  }

  private isAction(e: KeyboardEvent): boolean {
    return this.isSelectAll(e) || this.isCopy(e) || this.isPaste(e) || this.isCut(e);
  }

  private isSelectAll(e: KeyboardEvent): boolean {
    return (e.key === 'a' && (e.ctrlKey || e.metaKey));
  }

  private isCopy(e: KeyboardEvent): boolean {
    return (e.key === 'c' && (e.ctrlKey || e.metaKey));
  }

  private isPaste(e: KeyboardEvent): boolean {
    return (e.key === 'v' && (e.ctrlKey || e.metaKey));
  }

  private isCut(e: KeyboardEvent): boolean {
    return (e.key === 'x' && (e.ctrlKey || e.metaKey));
  }
}
