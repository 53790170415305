import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { guidGuard } from './guards/guid.guard';
import { NotFoundComponent } from './route-modules/not-found/not-found.component';
import { caseInsensitiveMatcher } from './libs/core';

export enum Views {
  access = '',
  entry = 'entry',
  status = 'status',
}

export const routes: Routes = [
  {
    path: ':entryId',
    canActivate: [guidGuard],
    children: [
      {
        path: Views.access,
        loadChildren: () => import('./route-modules/access/access.routes'),
      },
      {
        matcher: caseInsensitiveMatcher(Views.entry),
        canActivate: [authGuard],
        loadChildren: () => import('./route-modules/entry/entry.routes'),
      },
      {
        matcher: caseInsensitiveMatcher(Views.status),
        canActivate: [authGuard],
        loadChildren: () => import('./route-modules/status/status.routes'),
      },
    ],
  },
  {
    path: '',
    component: NotFoundComponent,
  },
];
