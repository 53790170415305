import * as t from 'io-ts';
import { nullable } from '../io-ts/nullable';

const IdCodeName = t.type({
  id: t.number,
  code: t.string,
  name: nullable(t.string),
}, 'IdCodeName');

type IdCodeName = t.TypeOf<typeof IdCodeName>;
export { IdCodeName };
