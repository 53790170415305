import { MatDialogRef } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DestroyRef } from '@angular/core';
import { tap } from 'rxjs/operators';

export const escapeDialog = <T, R>(
  ref: MatDialogRef<T, R>,
  componentRef: DestroyRef,
  onCancel: () => void,
): void => {
  ref
    .keydownEvents()
    .pipe(takeUntilDestroyed(componentRef))
    .subscribe((event) => {
      if (event.key === 'Escape') {
        onCancel();
      }
    });

  ref
    .backdropClick()
    .pipe(takeUntilDestroyed(componentRef))
    .subscribe(() => {
      onCancel();
    });
};

export const lockFormHeaderPosition = (): void => {
  const header = document.querySelector('.form-header') as HTMLElement;

  if (!header) {
    return;
  }

  let { top } = header.getBoundingClientRect();

  if (top !== 0) {
    return;
  }

  header.style.position = 'relative';
  top = header.getBoundingClientRect().top;
  header.style.top = `${top * -1}px`;
};

export const unlockFormHeaderPosition = <T, R>(
  ref: MatDialogRef<T, R>,
  componentRef: DestroyRef,
): void => {
  ref.afterClosed()
    .pipe(
      tap(() => {
        const header = document.querySelector('.form-header') as HTMLElement;

        if (!header) {
          return;
        }

        header.style.removeProperty('top');
        header.style.removeProperty('position');
      }),
      takeUntilDestroyed(componentRef),
    ).subscribe(() => {
      // do nothing
    });
};
