<div
  *ngIf="uploadFieldsProgress.length"
  class="form-header-uploads-wrapper"
>
  <div class="form-header-uploads">
    <div class="form-header-uploads__content">
      <div class="form-header-uploads__header">
        <h2>File Upload Progress</h2>
      </div>
      <div class="form-header-uploads__uploads">
        <div
          *ngFor="let uploadField of uploadFieldsProgress;  trackBy: uploadFieldsTrackByFn"
          class="form-header-uploads__file-upload"
        >
          <span>{{ uploadField.key }}</span>
          <mat-progress-bar
            class="form-header-uploads__progress"
            mode="determinate"
            [value]="uploadField.completed ? 100 : uploadField.progress"
          ></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>
