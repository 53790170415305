import * as t from 'io-ts';
import { isValid, formatISO } from 'date-fns';
import { either } from 'fp-ts/Either';

const adjustToUtc = (date: Date): Date => new Date(
  date.getUTCFullYear(),
  date.getUTCMonth(),
  date.getUTCDate(),
  date.getUTCHours(),
  date.getUTCMinutes(),
  date.getUTCSeconds(),
);

export const DateString = new t.Type<Date, string, unknown>(
  'DateString',
  (u): u is Date => u instanceof Date,
  (u, c) => either.chain(
    t.string.validate(u, c),
    (s) => {
      const d = adjustToUtc(new Date(s));

      return (isValid(d) ? t.success(d) : t.failure(u, c));
    },
  ),
  (a) => formatISO(a),
);
