import { coerceBoolean } from 'coerce-property';
import {
  Component, Input, ChangeDetectionStrategy,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

type IndicatorPosition = 'top' | 'center';
type IndicatorDelay = 'short' | 'long';

@Component({
  selector: 'procure-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatProgressSpinnerModule],
})
export class LoadingIndicatorComponent {
  @Input()
  public diameter: number = 40;

  @Input()
  public position: IndicatorPosition = 'center';

  @Input()
  public delay: IndicatorDelay = 'short';

  @Input() @coerceBoolean public active: boolean | null | '' = false;
}
