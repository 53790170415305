import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

const regex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;

export const guidGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const { entryId } = route.params;

  if (entryId && regex.test(entryId)) {
    return true;
  }

  return router.createUrlTree(['/']);
};
