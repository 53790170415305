<div class="app-header">
  <soq-app-browser-notification-header *ngIf="isBrowserHeaderVisible$ | async" (hide)="hideBrowserHeader()"></soq-app-browser-notification-header>
  <procure-environment-banner
    *ngIf="isEnvironmentBannerEnabled || isBeta"
    [identifier]="isBeta ? 'beta' : environmentInfo.id"
  >
    <ng-container *ngIf="isBeta">
      <b>{{ applicationName }} BETA</b> (Version: {{ environmentInfo.version }})
    </ng-container>
    <ng-container *ngIf="!isBeta">
      Environment: <b>{{ environmentInfo.title }}</b>, Version: <b>{{ environmentInfo.version }}</b>, Build: <b>{{ environmentInfo.buildNumber }}</b>
    </ng-container>
  </procure-environment-banner>
  <div class="app-header__container">
    <div class="app-header__brands">
      <a
        href="https://www.hitachienergy.com"
        target="_blank"
        class="app-header__logo-container app-header__logo-container-hitachi-energy"
      >
        <img
          src="./assets/images/logo-hitachi-energy.svg"
          class="logo__img logo__img--small"
          alt="Hitachi Energy Logo"
        />
      </a>
      <div class="app-header__logo-container app-header__logo-container--app">
        <img
          src="./assets/images/logo-procure-brand-small.svg"
          class="logo__img logo__img--small"
          alt="Supplier Online Quotation App Logo"
        />
        <span class="app-header__name">
          {{ applicationName }}
        </span>
      </div>
    </div>
    <div class="app-header__brands">
      <a
        href="https://www.hitachi.com"
        target="_blank"
        class="app-header__logo-container app-header__logo-container--hitachi"
      >
        <img
          src="./assets/images/logo-hitachi.svg"
          class="logo__img logo__img--small"
          alt="Hitachi Logo"
        />
      </a>
    </div>
  </div>
</div>
