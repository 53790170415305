import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationStatusResponse } from '../types/application-status-response.type';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class VersionApiService extends ApiService {
  public getApplicationStatus$(): Observable<ApplicationStatusResponse | null> {
    return this.baseApiService.get$<ApplicationStatusResponse>('Version', ApplicationStatusResponse)
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error, {
            text: 'There was an issue retrieving the user data.',
          });

          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }
}
