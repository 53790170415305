<div class="mat-paginator-outer-container">
  <div class="mat-paginator-container">
    <div *ngIf="!hidePageSize" class="mat-paginator-page-size">
      <div class="mat-paginator-page-size-label">
        {{ _intl.itemsPerPageLabel }}
      </div>
      <mat-form-field
        *ngIf="_displayedPageSizeOptions.length > 1"
        class="mat-paginator-page-size-select"
        subscriptSizing="dynamic"
      >
        <mat-select
          [value]="pageSize"
          [disabled]="disabled"
          [aria-label]="_intl.itemsPerPageLabel"
          [id]="id + '-page-size'"
          (selectionChange)="_changePageSize($event.value)"
        >
          <mat-option
            *ngFor="let pageSizeOption of _displayedPageSizeOptions; let i = index; trackBy: pageSizeOptionsTrackByFn"
            [value]="pageSizeOption"
          >
            {{ pageSizeLabels[i] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div
        *ngIf="_displayedPageSizeOptions.length <= 1"
        class="mat-paginator-page-size-value"
      >
        {{pageSize}}
      </div>
    </div>
    <div class="mat-paginator-range-actions">
      <div class="mat-paginator-range-label">
        {{ _intl.getRangeLabel(pageIndex, pageSize, length) }} {{ suffix }}
      </div>
      <button
        *ngIf="showFirstLastButtons"
        mat-icon-button
        type="button"
        class="mat-paginator-navigation-first"
        [attr.aria-label]="_intl.firstPageLabel"
        [matTooltip]="_intl.firstPageLabel"
        [matTooltipDisabled]="_previousButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="_previousButtonsDisabled()"
        [id]="id + '-first'"
        (click)="firstPage()"
      >
        <svg class="mat-paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"/>
        </svg>
      </button>
      <button
        mat-icon-button
        type="button"
        class="mat-paginator-navigation-previous"
        [attr.aria-label]="_intl.previousPageLabel"
        [matTooltip]="_intl.previousPageLabel"
        [matTooltipDisabled]="_previousButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="_previousButtonsDisabled()"
        [id]="id + '-prev'"
        (click)="previousPage()"
      >
        <svg class="mat-paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
        </svg>
      </button>
      <button
        mat-icon-button
        type="button"
        class="mat-paginator-navigation-next"
        [attr.aria-label]="_intl.nextPageLabel"
        [matTooltip]="_intl.nextPageLabel"
        [matTooltipDisabled]="_nextButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="_nextButtonsDisabled()"
        [id]="id + '-next'"
        (click)="nextPage()"
      >
        <svg class="mat-paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
        </svg>
      </button>
      <button
        *ngIf="showFirstLastButtons"
        mat-icon-button
        type="button"
        class="mat-paginator-navigation-last"
        [attr.aria-label]="_intl.lastPageLabel"
        [matTooltip]="_intl.lastPageLabel"
        [matTooltipDisabled]="_nextButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="_nextButtonsDisabled()"
        [id]="id + '-last'"
        (click)="lastPage()"
      >
        <svg class="mat-paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"/>
        </svg>
      </button>
    </div>
  </div>
</div>
