import * as t from 'io-ts';

const UploadSettings = t.type({
  host: t.string,
  container: t.string,
  token: t.string,
}, 'UploadSettings');

type UploadSettings = t.TypeOf<typeof UploadSettings>;
export { UploadSettings };
