import { Component, Inject } from '@angular/core';
import { getYear } from 'date-fns';
import { ButtonComponent } from '../button/button.component';
import { APPLICATION_NAME } from '../../libs/core';

@Component({
  selector: '[soq-app-footer]',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  standalone: true,
  imports: [ButtonComponent],
})
export class AppFooterComponent {
  public readonly version: string = APP_VERSION;
  public currentYear: number = getYear(new Date());
  public isLoading: boolean = false;
  public readonly link: string = 'https://www.hitachienergy.com/privacy-notices';
  public readonly label: string = 'Hitachi Energy Privacy Notice';

  constructor(
    @Inject(APPLICATION_NAME) public readonly applicationName: string,
  ) { }
}
