import { Pipe, PipeTransform } from '@angular/core';
import { getFileExtensionFromFilename } from '../utilities/file-type.utility';

@Pipe({
  name: 'fileExtension',
  standalone: true,
})
export class FileExtensionPipe implements PipeTransform {
  /**
   * transforms a filename to a file extension
   */
  public transform(value: string): string {
    return getFileExtensionFromFilename(value);
  }
}
