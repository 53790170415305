import {
  Inject, Injectable, OnDestroy, NgZone,
} from '@angular/core';
import { WINDOW } from '../injection-tokens';
import { CanBlockNavigation } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class RouteNavigationService implements OnDestroy {
  private listeners: Set<CanBlockNavigation> = new Set();

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private zone: NgZone,
  ) {
    this.handleBeforeUnloadEvent = this.handleBeforeUnloadEvent.bind(this);

    this.zone.runOutsideAngular(
      (): void => {
        this.window.addEventListener('beforeunload', this.handleBeforeUnloadEvent);
      },
    );
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.window.removeEventListener('beforeunload', this.handleBeforeUnloadEvent);
    this.listeners.clear();
  }

  /**
   * Registers a component which should be checked before a route navigation.
   */
  public register(listener: CanBlockNavigation): void {
    this.listeners.add(listener);
  }

  /**
   * Unregisters a component from a route navigation checks.
   */
  public unregister(listener: CanBlockNavigation): void {
    this.listeners.delete(listener);
  }

  private handleBeforeUnloadEvent(event: BeforeUnloadEvent): void {
    if (!this.canUnload()) {
      event.preventDefault();
      event.returnValue = true;
    } else {
      delete event.returnValue;
    }
  }

  private canUnload(): boolean {
    // eslint-disable-next-line no-restricted-syntax
    for (const listener of this.listeners) {
      const result = this.zone.runGuarded(
        () => listener.canUnload(),
      );

      if (!result) {
        return false;
      }
    }

    return true;
  }
}
