import { enableDebugTools } from '@angular/platform-browser';
import { ApplicationRef } from '@angular/core';
import { environment } from './environments/environment';

// Angular debug tools in the dev console
// https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
let ref = function identity<T>(value: T): T { return value; };

if (!environment.production) {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  ref = (module: any) => {
    const applicationRef = module.injector.get(ApplicationRef);
    const appComponent = applicationRef.components[0];

    enableDebugTools(appComponent);

    return module;
  };
} else {
  // Production
  // disableDebugTools();
}

export const decorateModuleRef = ref;
