import { Injectable } from '@angular/core';
import { HttpUrlEncodingCodec } from '@angular/common/http';

/**
 * @TODO Check this Angular Bug concerning HttpUrlEncoding : https://github.com/angular/angular/issues/18261
 */
@Injectable({
  providedIn: 'root',
})
export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  /** @inheritdoc */
  public encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  /** @inheritdoc */
  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}
