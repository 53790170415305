import { FormControl, AbstractControl } from '@angular/forms';

export const isRequired = (control: FormControl): boolean => {
  if (!control?.validator) {
    return false;
  }

  const validator = control.validator({} as AbstractControl);

  return validator && validator.required;
};
