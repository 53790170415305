import {
  Component, ContentChild, HostBinding, Input,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { coerceBoolean } from 'coerce-property';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'soq-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  standalone: true,
  imports: [NgIf, MatProgressSpinnerModule],
})
export class IconButtonComponent {
  @ContentChild(MatIcon, { static: false }) public readonly icon?: MatIcon;

  @HostBinding('attr.disabled')
  public get isDisabled(): boolean | null {
    return this.loading === true || this.disabled === true || null;
  }

  @Input() @coerceBoolean public loading: boolean | null | '' = false;
  @Input() @coerceBoolean public disabled: boolean | null | '' = false;
}
