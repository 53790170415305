<header class="dialog__header">
  <h2 class="dialog__title">{{ title }}</h2>
</header>
<mat-dialog-content>
  <div
    class="dialog__body"
    [innerHTML]="text"
  ></div>
  <div
    *ngIf="info"
    class="dialog__info"
  >
    <mat-icon class="dialog__info-icon">info</mat-icon>
    <div>
      {{ info }}
    </div>
  </div>
</mat-dialog-content>
<footer class="dialog__footer">
  <button
    *ngIf="type === dialogType.PROMPT"
    type="button"
    procure-stroked-button
    id="dialog-no"
    [mat-dialog-close]="false"
    [disabled]="isConfirming"
  >
   {{ secondaryAction || 'No' }}
  </button>
  <button
    *ngIf="type === dialogType.PROMPT"
    type="button"
    procure-flat-button
    color="primary"
    id="dialog-yes"
    [disabled]="isConfirming"
    [loading]="isConfirming"
    (click)="confirm()"
  >
    {{ primaryAction || 'Yes' }}
  </button>
  <button
    *ngIf="type !== dialogType.PROMPT"
    type="button"
    procure-flat-button
    color="primary"
    id="dialog-ok"
    [mat-dialog-close]="!blackout"
    (click)="close()"
  >
    {{ primaryAction || 'OK' }}
  </button>
</footer>
