import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';
import { EnvironmentConfig } from './environment-config';

export type EnvironmentInfo = {
  id: string;
  title: string;
  version: string;
  buildNumber: string;
}

export const APPLICATION_NAME = new InjectionToken<string>('APPLICATION_NAME');

export const ENVIRONMENT_INFO = new InjectionToken<EnvironmentInfo>('ENVIRONMENT_INFO');
export const ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentConfig>('ENVIRONMENT_CONFIG');

export const WINDOW = new InjectionToken<Window>(
  'An abstraction over the global window object',
  {
    factory: (): Window => {
      const { defaultView } = inject(DOCUMENT);

      if (!defaultView) {
        throw new Error('Window is not available');
      }

      return defaultView;
    },
  },
);

export const DRAFT_STATUS_COLOR = new InjectionToken<string>('DRAFT_STATUS_COLOR', {
  factory: (): string => '#868686',
});

export const PROGRESS_STATUS_COLOR = new InjectionToken<string>('PROGRESS_STATUS_COLOR', {
  factory: (): string => '#4782ff',
});

export const DONE_STATUS_COLOR = new InjectionToken<string>('DONE_STATUS_COLOR', {
  factory: (): string => '#00b102',
});

export const FAIL_STATUS_COLOR = new InjectionToken<string>('FAIL_STATUS_COLOR', {
  factory: (): string => '#00b102',
});

export const HOLD_STATUS_COLOR = new InjectionToken<string>('FAIL_STATUS_COLOR', {
  factory: (): string => '#f87f00',
});
