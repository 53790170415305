<ng-content />
<div
  *ngIf="loading"
  class="button__spinner-wrapper"
>
  <mat-spinner
    class="button__spinner"
    [diameter]="16"
  />
</div>
<div
  *ngIf="disabled"
  class="button__disabled-overlay"
></div>
