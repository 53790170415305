import { Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  selector: 'soq-app-browser-notification-header',
  templateUrl: './app-browser-notification-header.component.html',
  styleUrls: ['./app-browser-notification-header.component.scss'],
  standalone: true,
  imports: [ButtonComponent, MatIconModule, IconButtonComponent],
})
export class AppBrowserNotificationHeaderComponent {
  @Output() public readonly hide = new EventEmitter<void>();

  public dismiss(): void {
    this.hide.emit();
  }
}
