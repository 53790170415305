import { coerceBoolean } from 'coerce-property';
import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild,
} from '@angular/core';
import { MatExpansionPanel, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { NgChanges } from '../../utilities/angular-types.utility';
import { NumberBadgeComponent } from '../number-badge/number-badge.component';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';

const DEFAULT_PANEL_HEIGHT: string = '64';

@Component({
  selector: 'procure-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LoadingIndicatorComponent,
    MatExpansionModule,
    NgIf,
    MatIconModule,
    NumberBadgeComponent,
  ],
})
export class ExpansionPanelComponent implements OnInit, OnChanges {
  @ViewChild(MatExpansionPanel, { static: true })
  public expansionPanel?: MatExpansionPanel;

  @Input()
  public label: string = '';

  @Input()
  public badge?: number;

  @Input()
  public color?: string;

  @Input()
  public showZeroBadge: boolean = false;

  @Input()
  public icon? = '';

  @Input()
  public expansionPanelClass: string[] = ['mat-elevation-z0'];

  @Input()
  public loading: boolean | null = false;

  @Input() @coerceBoolean public expanded: boolean | '' = true;

  @Input() @coerceBoolean public hideToggle: boolean | '' = false;

  @Input()
  public panelHeight: string = `${DEFAULT_PANEL_HEIGHT}px`;

  @Output()
  public readonly closed = new EventEmitter<void>();

  @Output()
  public readonly opened = new EventEmitter<void>();

  public visibleHeight = this.panelHeight;

  public ngOnInit(): void {
    this.setPanelHeaderHeight();
  }

  public ngOnChanges(changes: NgChanges<ExpansionPanelComponent>): void {
    if ('label' in changes || 'hideToggle' in changes) {
      this.setPanelHeaderHeight();
    }
  }

  public close(): void {
    this.expansionPanel?.close();
  }

  public open(): void {
    this.expansionPanel?.open();
  }

  public toggle(): void {
    this.expansionPanel?.toggle();
  }

  private setPanelHeaderHeight(): void {
    if (this.label.length === 0 && this.hideToggle) {
      this.visibleHeight = '0';
    } else if (this.visibleHeight !== this.panelHeight) {
      this.visibleHeight = this.panelHeight;
    }
  }
}
