import * as t from 'io-ts';

export enum AuthorisationResult {
  Accepted = 1,
  AccessDenied = 2,
  TokenExpired = 3,
  RequestForQuotationNotFound = 4,
}

export enum EntryStatus {
  New = 0,
  Accessed = 1,
  Rejected = 2,
  Confirmed = 3,
  Submitted = 4,
}

const AccessResponse = t.type({
  authorisationResult: t.number,
  entryStatus: t.number,
}, 'AccessResponse');

type AccessResponse = t.TypeOf<typeof AccessResponse>;
export { AccessResponse };
