import {
  ChangeDetectionStrategy, Component, Input, ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'procure-show-field',
  templateUrl: './show-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class ShowFieldComponent {
  @Input() public label: string = '';
}
