import { ErrorHandler, LOCALE_ID, Provider } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material/datepicker';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter } from '@angular/material/core';
import { DateInterceptor } from './interceptors/date-interceptor';
import { environment } from '../environments/environment';
import { COMPANY_NAME } from './constants';
import { CustomDateAdapter } from './dates/custom-date-adapter';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { GlobalErrorHandlerService } from './libs/application-insights';
import {
  APPLICATION_NAME, ENVIRONMENT_CONFIG, ENVIRONMENT_INFO, DEFAULT_DIALOG_WIDTH, LocaleService,
} from './libs/core';

const httpInterceptorProviders: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
];

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return (): CloseScrollStrategy => overlay.scrollStrategies.close();
}

export const APP_PROVIDERS: Provider[] = [
  {
    provide: APPLICATION_NAME,
    useValue: 'Supplier Online Quotation',
  },
  {
    provide: ENVIRONMENT_CONFIG,
    useValue: {
      production: environment.production,
      title: environment.title,
      isEnvironmentBannerEnabled: environment.isEnvironmentBannerEnabled,
      apiEndpoint: environment.apiEndpoint,
      instrumentationKey: environment.instrumentationKey,
    },
  },
  {
    provide: ENVIRONMENT_INFO,
    useValue: {
      id: environment.title.toLowerCase(),
      title: environment.title,
      version: APP_VERSION,
      buildNumber: BUILD_NUMBER,
    },
  },
  {
    provide: COMPANY_NAME,
    useValue: 'Hitachi Energy',
  },
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlerService,
  },
  Title,
  FormBuilder,
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      width: DEFAULT_DIALOG_WIDTH,
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
    },
  },
  {
    provide: LOCALE_ID,
    useFactory: (localeService: LocaleService) => localeService.getLocale(),
    deps: [LocaleService],
  },
  {
    provide: MAT_DATEPICKER_SCROLL_STRATEGY,
    deps: [Overlay],
    useFactory: scrollFactory,
  },
  {
    provide: MAT_SELECT_SCROLL_STRATEGY,
    deps: [Overlay],
    useFactory: scrollFactory,
  },
  {
    provide: ENVIRONMENT_CONFIG,
    useValue: {
      production: environment.production,
      title: environment.title,
      isEnvironmentBannerEnabled: environment.isEnvironmentBannerEnabled,
      apiEndpoint: environment.apiEndpoint,
      instrumentationKey: environment.instrumentationKey,
    },
  },
  {
    provide: ENVIRONMENT_INFO,
    useValue: {
      id: environment.title.toLowerCase(),
      title: environment.title,
      version: APP_VERSION,
      buildNumber: BUILD_NUMBER,
    },
  },
  {
    provide: COMPANY_NAME,
    useValue: 'Hitachi Energy',
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      width: DEFAULT_DIALOG_WIDTH,
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
    },
  },
  {
    provide: DateAdapter,
    useClass: CustomDateAdapter,
  },
  ...httpInterceptorProviders,
];
