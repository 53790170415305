export const DEFAULT_TRUNCATED_STRING_LENGTH = 140;

export const DEFAULT_SMALL_DIALOG_WIDTH = '450px';
export const DEFAULT_DIALOG_WIDTH = '500px';
export const DEFAULT_MIDDLE_DIALOG_WIDTH = '1000px';
export const DEFAULT_BIG_DIALOG_WIDTH = '1500px';

export const MAX_INT = 2147483647;

export const BADGE_COLOR = '#ffffff';
export const BADGE_BACKGROUND_COLOR = '#555555';

export const DEFAULT_LAYOUT_GAP = 10;
