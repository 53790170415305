import * as t from 'io-ts';
import { IdCodeName, IdName } from '../libs/core';

const LookupData = t.type({
  currencies: t.array(IdCodeName),
  incotermsTypes: t.array(IdName),
  paymentTerms: t.array(IdName),
  countries: t.array(IdCodeName),
  quotationTypes: t.array(IdName),
  procureEmail: t.string,
}, 'LookupData');

type LookupData = t.TypeOf<typeof LookupData>;
export { LookupData };
