import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalAccessState } from '../store/global-access.state';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private readonly store = inject(Store);
  private router = inject(Router);

  public intercept(
    req: HttpRequest<Record<string, unknown>>,
    next: HttpHandler,
  ): Observable<HttpEvent<Record<string, unknown>>> {
    const entryId = this.router.url.split('/')[1];
    const tokens = this.store.selectSnapshot(GlobalAccessState.accessTokens);
    const token = tokens[entryId];

    if (token) {
      return next.handle(req.clone({
        headers: req.headers.append('Token', token),
      }));
    }

    return next.handle(req);
  }
}
