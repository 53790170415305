<div class="app-footer__info">
  <div>
    © {{ currentYear }} - {{ applicationName }} - {{ version }}
  </div>
  <div>
    <a
    target="_blank"
    class="app-footer__privacy-notices-link"
    rel="noopener noreferrer"
    [href]="link"
  >
    {{ label }}
  </a>
  </div>
</div>
