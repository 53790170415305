import {
  Directive,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[inputSelectOnFocus]',
  standalone: true,
})
export class InputSelectOnFocusDirective {
  @HostListener('focus', ['$event']) public onFocus($event: any): void {
    $event?.target?.select();
  }
}
