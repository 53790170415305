const FILE_TYPE_FALLBACK: string = 'bin';

/**
 * try to read out the file extension in case where the mimeType of a file is unknown
 */
export function getFileExtensionFromFilename(fileName: string): string {
  if (fileName === undefined || fileName === null || fileName.length === 0) {
    return '';
  }

  let newFileType = '';
  const splittedFileName = fileName.split('.');

  if (splittedFileName.length > 1) {
    newFileType = splittedFileName[splittedFileName.length - 1];
  } else {
    newFileType = FILE_TYPE_FALLBACK;
  }

  return newFileType.toLowerCase();
}
