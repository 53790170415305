import { DestroyRef, Injectable, inject } from '@angular/core';
import {
  Action, Selector, State, StateContext,
} from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GlobalAccessApiService } from '../services/global-access-api.service';
import { AuthorisationResult, EntryStatus } from '../types/access-response.type';
import { SnackbarService } from '../libs/core';
import { CheckAccess } from '../actions';

export type GlobalAccessStateShape = {
  authorisationResults: Record<string, AuthorisationResult | undefined>;
  accessTokens: Record<string, string | null>;
  statuses: Record<string, EntryStatus | null | undefined>;
  isLoading: boolean;
}

@Injectable({
  providedIn: 'root',
})
@State<GlobalAccessStateShape>({
  name: 'globalAccess',
  defaults: {
    authorisationResults: {},
    accessTokens: {},
    statuses: {},
    isLoading: false,
  },
})
export class GlobalAccessState {
  private readonly globalAccessApiService = inject(GlobalAccessApiService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly snackbarService = inject(SnackbarService);

  @Selector()
  public static authorisationResults(state: GlobalAccessStateShape): Record<string, AuthorisationResult | undefined> {
    return state.authorisationResults;
  }

  @Selector()
  public static accessTokens(state: GlobalAccessStateShape): Record<string, string | null> {
    return state.accessTokens;
  }

  @Selector()
  public static statuses(state: GlobalAccessStateShape): Record<string, EntryStatus | null | undefined> {
    return state.statuses;
  }

  @Selector()
  public static isLoading(state: GlobalAccessStateShape): boolean {
    return state.isLoading;
  }

  @Action(CheckAccess)
  public checkAccess(
    { patchState, getState }: StateContext<GlobalAccessStateShape>,
    { entryId, token }: CheckAccess,
  ): void {
    patchState({
      isLoading: true,
    });

    this.globalAccessApiService.checkAccess$(entryId, token)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        patchState({
          isLoading: false,
        });

        if (!response) {
          return;
        }

        if (response.authorisationResult === AuthorisationResult.AccessDenied) {
          this.snackbarService.showErrorSnackbar({
            text: 'Request for Quotation access denied.',
          });
        }

        if (response.authorisationResult === AuthorisationResult.TokenExpired) {
          this.snackbarService.showErrorSnackbar({
            text: 'Request for Quotation has expired.',
          });
        }

        if (response.authorisationResult === AuthorisationResult.RequestForQuotationNotFound) {
          this.snackbarService.showErrorSnackbar({
            text: 'Request for Quotation not found.',
          });
        }

        patchState({
          statuses: { ...getState().statuses, [entryId]: response.entryStatus },
          authorisationResults: { ...getState().authorisationResults, [entryId]: response.authorisationResult },
          accessTokens: { ...getState().accessTokens, [entryId]: token },
        });
      });
  }
}
