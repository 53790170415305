<div class="expansion-panel">
  <procure-loading-indicator [active]="loading"></procure-loading-indicator>
  <mat-accordion>
    <mat-expansion-panel
      [class]="expansionPanelClass"
      [expanded]="expanded"
      [hideToggle]="hideToggle"
      [disabled]="hideToggle"
      (closed)="closed.emit()"
      (opened)="opened.emit()"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="visibleHeight"
        [expandedHeight]="visibleHeight"
      >
        <mat-panel-title>
          <mat-icon *ngIf="icon">
            {{ icon }}
          </mat-icon>
          <procure-number-badge
            *ngIf="badge || (badge === 0 && showZeroBadge)"
            [value]="badge || 0"
            [clickable]="false"
          ></procure-number-badge>
          <div
            *ngIf="color"
            class="expansion-panel__color"
            [style.background-color]="color"
          ></div>
          {{ label }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-content></ng-content>
    </mat-expansion-panel>
  </mat-accordion>
</div>
