import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LookupData } from '../types/lookup-data.type';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LookupDataApiService extends ApiService {
  public fetchLookupData$(): Observable<LookupData | null> {
    return this.baseApiService.get$<LookupData>('Lookup', LookupData)
      .pipe(
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error, {
            text: 'There was an issue while retrieving lookup data.',
          });

          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }
}
