// eslint-disable-next-line max-classes-per-file
export class FetchLookupData {
  public static readonly type: string = '[Lookup] Fetch lookup data';
}

export class FetchUploadSettings {
  public static readonly type: string = '[Lookup] Fetch upload settings';

  constructor(public readonly entryId: string) {}
}

export class CheckAccess {
  public static readonly type = '[Access] Check access';

  constructor(
    public readonly entryId: string,
    public readonly token: string,
  ) {}
}
