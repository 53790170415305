<div class="status-badge">
  <div
    class="status-badge__indicator"
    [style.color]="statusColor"
    [style.background-color]="statusColor"
  ></div>
  <procure-badge
    class="status-badge__badge"
    [label]="label"
    [borderColor]="statusColor"
    [inverted]="inverted"
  ></procure-badge>
</div>
