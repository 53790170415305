import { EnvironmentConfig } from './environment-config';

const apiEndpoint = API_ENDPOINT || 'https://api.stage.projectprocure-online-quotation.hitachienergy.com/api/';
const instrumentationKey = APPINSIGHTS_INSTRUMENTATIONKEY || '';

/**
 * This is the environment configuration for the 'staging' context of the project.
 *
 * The following contexts or environments are available:
 *  - development (environment.ts): For local development.
 *  - develop (environment.develop.ts): First deploy after merges. Development built for production.
 *  - staging (environment.staging.ts): Staging environment before production (acceptance tests).
 *  - production (environment.production.ts): Final stage for live application.
 */
export const environment: EnvironmentConfig = {
  production: true,
  title: 'Staging',
  isEnvironmentBannerEnabled: true,
  displayBetaBannerIfEnabled: false,
  apiEndpoint,
  instrumentationKey,
};
