import {
  DestroyRef,
  EventEmitter, Injectable, Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { timer } from 'rxjs';

const CANCEL_DRAG_EVENT_THRESHOLD_MILLISECONDS = 100;
const DEBOUNCE_DOME_UPDATES_MILLISECONDS = 100;

@Injectable({
  providedIn: 'root',
})
export class DocumentDragService {
  @Output()
  public readonly isDragging: EventEmitter<boolean> = new EventEmitter<boolean>();

  private lastPositiveEventFire: number = 0;

  private _currentState: boolean = false;

  private get isDragLeaveNotFiredByDomUpdate(): boolean {
    return Date.now() - this.lastPositiveEventFire > DEBOUNCE_DOME_UPDATES_MILLISECONDS;
  }

  private get currentState(): boolean {
    return this._currentState;
  }

  /**
   * Save the state in local variable and fire the State
   */
  private set currentState(state: boolean) {
    this._currentState = state;
    this.isDragging.emit(state);
  }

  constructor(private readonly componentRef: DestroyRef) {
    document.body.addEventListener('dragover', this.onDragOver.bind(this));
    document.body.addEventListener('dragleave', this.onDragLeave.bind(this));
  }

  private onDragOver(): void {
    // just save if a different state appears, to prevent unneeded event fires
    if (!this.currentState) {
      this.currentState = true;
    }
    this.lastPositiveEventFire = Date.now();
  }

  private onDragLeave(): void {
    // just fire different state and when no DOM updates was trigger this event
    if (this.currentState && this.isDragLeaveNotFiredByDomUpdate) {
      this.currentState = false;
    }
    this.startDragCancel(CANCEL_DRAG_EVENT_THRESHOLD_MILLISECONDS);
  }

  /**
   * Since dragover fires every millisecond, you can fire a a false event if the file
   * leaves the browser window and no dragover event was fired.
   */
  private startDragCancel(cancelThreshold: number): void {
    timer(cancelThreshold)
      .pipe(takeUntilDestroyed(this.componentRef))
      .subscribe(() => {
        this.currentState = false;
      });
  }
}
