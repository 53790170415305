import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { VersionApiService } from '../../services/version-api.service';
import {
  EnvironmentBannerComponent, ENVIRONMENT_INFO, APPLICATION_NAME, EnvironmentInfo, BrowserDetectionService,
} from '../../libs/core';
import { AppBrowserNotificationHeaderComponent } from '../app-browser-header/app-browser-notification-header.component';

@Component({
  selector: '[soq-app-header]',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    EnvironmentBannerComponent,
    RouterLink,
    AppBrowserNotificationHeaderComponent,
    AsyncPipe,
  ],
})
export class AppHeaderComponent {
  public isBeta: boolean = false;
  public readonly isEnvironmentBannerEnabled: boolean = environment.isEnvironmentBannerEnabled;
  public readonly displayBetaBannerIfEnabled: boolean = environment.displayBetaBannerIfEnabled;

  private readonly browserDetectionService = inject(BrowserDetectionService);
  private readonly versionApiService = inject(VersionApiService);

  public readonly isBrowserHeaderVisible$ = new BehaviorSubject<boolean>(
    !this.browserDetectionService.isRecommendedBrowser,
  );

  constructor(
    @Inject(APPLICATION_NAME) public readonly applicationName: string,
    @Inject(ENVIRONMENT_INFO) public readonly environmentInfo: EnvironmentInfo,
  ) {
    this.versionApiService.getApplicationStatus$()
      .pipe(takeUntilDestroyed())
      .subscribe((response) => {
        if (this.displayBetaBannerIfEnabled && response) {
          this.isBeta = response.isBeta;
        }
      });
  }

  public hideBrowserHeader(): void {
    this.isBrowserHeaderVisible$.next(false);
  }
}
