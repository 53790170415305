<div class="snackbar">
  <div
    *ngIf="data.type"
    [attr.class]="'snackbar__icon-container snackbar__icon-container--' + icon"
  >
    <mat-icon class="snackbar__icon" data-testid="icon">
      {{ icon }}
    </mat-icon>
  </div>
  <div class="snackbar__content">
    <div
      *ngIf="data.title"
      class="snackbar__title"
    >
      {{ data.title }}
    </div>
    <div
      *ngIf="data.text"
      class="snackbar__text"
    >
      {{ data.text }}
    </div>
  </div>
</div>
