import { enableProdMode, importProvidersFrom } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxsStoragePluginModule, SESSION_STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { MatNativeDateModule } from '@angular/material/core';
import { AppComponent } from './app/app.component';
import { decorateModuleRef } from './module-decorators';
import { environment } from './environments/environment';
import { APP_PROVIDERS } from './app/app-module-providers';
import { routes } from './app/app.routes';
import { GlobalAccessState } from './app/store/global-access.state';
import { LookupDataState } from './app/store/lookup-data.state';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled',
        bindToComponentInputs: true,
      }),
      BrowserModule,
      MatDialogModule,
      MatSnackBarModule,
      MatNativeDateModule,
      NgxsModule.forRoot([GlobalAccessState, LookupDataState], {
        developmentMode: !environment.production,
      }),
      NgxsLoggerPluginModule.forRoot({
        disabled: true,
      }),
      NgxsStoragePluginModule.forRoot({
        key: [
          {
            key: GlobalAccessState,
            engine: SESSION_STORAGE_ENGINE,
          },
        ],
        afterDeserialize: (obj, key) => {
          if (key === 'globalAccess') {
            return {
              ...obj,
              isLoading: false,
            };
          }
          return obj;
        },
      }),
    ),
    ...APP_PROVIDERS,
    provideEnvironmentNgxMask(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
  .then(decorateModuleRef)
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });
