import { Injectable, inject } from '@angular/core';
import { format } from 'date-fns';
import { ApplicationInsightsService } from './application-insights.service';
import { CustomHttpErrorResponse, ValidationResult } from '../../api';
import { SnackbarService, SnackbarData } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private readonly appInsightsService = inject(ApplicationInsightsService);
  private readonly snackbarService = inject(SnackbarService);

  /**
   * Tracks the error through Application Insights without notifying the user.
   */
  public trackError(error: Error): void {
    // eslint-disable-next-line no-console
    console.error(error);
    this.appInsightsService.trackException({ exception: error });
  }

  /**
   * Displays a notification to the user and tracks the error through
   * Application Insights.
   */
  public handleError(error: unknown, data?: SnackbarData): void {
    if (!(error instanceof Error) && !(error instanceof CustomHttpErrorResponse)) {
      return;
    }

    this.notifyUser(error, data);
    this.trackError(error);
  }

  /**
   * Displays an error notification to the user.
   */
  public notifyUser(error: Error, data?: SnackbarData): void {
    if (data) {
      this.snackbarService.showErrorSnackbar({
        ...data,
        text: this.getErrorMessage(error, data.text),
      });
    } else {
      this.snackbarService.showErrorSnackbar(this.formatError('An error occurred', error));
    }
  }

  private formatError(title: string, error: Error): SnackbarData {
    const formattedDate = `${format(new Date(), 'YYYY-MM-DD HH:mm:ss')} UTC`;

    const text = `
      ${error.message.replace('\n', '<br/>\n')}
      ${error.name ? `<span class="procure-api-error-status">(${error.name})</span>` : ''}
      <span class="procure-api-error-date">${formattedDate}</span>
    `;

    return {
      title,
      text,
    };
  }

  private getErrorMessage(error: Error, fallback: string | undefined): string | undefined {
    if (this.isErrorResponse(error) && this.isValidationResult(error.error)) {
      return Object.values(error.error.errors).flat().join('\n');
    }

    if (error.message && typeof error.message === 'string') {
      return error.message;
    }

    return fallback;
  }

  private isErrorResponse(error: unknown): error is CustomHttpErrorResponse {
    return (error as CustomHttpErrorResponse).error !== undefined;
  }

  private isValidationResult(error: unknown): error is ValidationResult {
    return (error as ValidationResult)?.traceId !== undefined;
  }
}
