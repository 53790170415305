import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export class CustomHttpErrorResponse extends HttpErrorResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public name: any;
  public message: string;

  constructor(
    name: string,
    message: string,
    init: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error?: any;
      headers?: HttpHeaders;
      status?: number;
      statusText?: string;
      url?: string;
    },
  ) {
    super(init);

    this.name = name;
    this.message = message;
  }
}
