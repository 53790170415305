import { coerceBoolean } from 'coerce-property';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import {
  MatPaginator,
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { NgChanges } from '../../utilities/angular-types.utility';
import { MAX_INT } from '../../constants';

@Component({
  templateUrl: './paginator.component.html',
  selector: 'procure-paginator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class PaginatorComponent extends MatPaginator implements OnInit, OnChanges {
  public static nextId: number = 0;

  @Input()
  public readonly id: string;

  @Input()
  public readonly suffix?: string;

  @Input() @coerceBoolean public disableAllOption: boolean | '' = false;

  @Input()
  public pageSizes: number[] = [10, 20, 50];

  public pageSizeLabels: readonly string[] = [];

  constructor(
    _intl: MatPaginatorIntl,
    _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) defaults?: MatPaginatorDefaultOptions,
  ) {
    super(_intl, _changeDetectorRef, defaults);

    this._intl.getRangeLabel = this.getRangeLabel;

    this.showFirstLastButtons = true;
    this.pageSizeOptions = this.getPageSizeOptions();
    this.pageSizeLabels = this.getPageSizeLabels();

    // eslint-disable-next-line prefer-destructuring
    this.pageSize = this.pageSizeOptions[1];

    this.id = `procure-paginator-${PaginatorComponent.nextId}`;
    PaginatorComponent.nextId += 1;
  }

  public ngOnChanges(changes: NgChanges<PaginatorComponent>): void {
    if ('disableAllOption' in changes && changes.disableAllOption.currentValue !== changes.disableAllOption.previousValue) {
      this.pageSizeOptions = this.getPageSizeOptions();
      this.pageSizeLabels = this.getPageSizeLabels();

      // eslint-disable-next-line prefer-destructuring
      this.pageSize = this.pageSizeOptions[1];
    }

    if ('pageSizes' in changes && changes.pageSizes.currentValue !== changes.pageSizes.previousValue) {
      this.pageSizeOptions = this.getPageSizeOptions();
      this.pageSizeLabels = this.getPageSizeLabels();

      // eslint-disable-next-line prefer-destructuring
      this.pageSize = this.pageSizeOptions[1];
    }
  }

  /**
   * TrackBy function for the page size options.
   */
  public pageSizeOptionsTrackByFn(index: number, pageSizeOption: number): number {
    return pageSizeOption;
  }

  private getPageSizeOptions(): number[] {
    return this.disableAllOption ? this.pageSizes : [...this.pageSizes, MAX_INT];
  }

  private getPageSizeLabels(): readonly string[] {
    const labels: string[] = this.getPageSizeOptions()
      .map((option) => String(option));

    if (!this.disableAllOption) {
      labels.splice(-1, 1, 'All');
    }

    return labels;
  }

  private getRangeLabel = (page: number, pageSize: number, length: number): string => {
    let rangeLength = length;

    if (rangeLength === 0 || pageSize === 0) {
      return `Showing 0 of ${rangeLength} entries`;
    }

    rangeLength = Math.max(rangeLength, 0);

    const startIndex = page * pageSize;

    const endIndex = startIndex < rangeLength
      ? Math.min(startIndex + pageSize, rangeLength)
      : startIndex + pageSize;

    return `Showing ${startIndex + 1} to ${endIndex} of ${rangeLength} entries`;
  };
}
