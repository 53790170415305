import { Injectable } from '@angular/core';
import {
  MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SnackbarComponent, SnackbarData, SnackbarType } from '../components/snackbar';

type SnackbarOptions = {
  readonly duration: number;
  readonly verticalPosition: MatSnackBarVerticalPosition;
  readonly horizontalPosition: MatSnackBarHorizontalPosition;
};

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly defaultOptions: SnackbarOptions = {
    duration: 10000,
    verticalPosition: 'top',
    horizontalPosition: 'center',
  };

  constructor(
    private readonly snackbar: MatSnackBar,
  ) {}

  /**
   * Opens a snackbar of type info.
   */
  public showInfoSnackbar(
    data: SnackbarData,
    options?: Partial<SnackbarOptions>,
  ): MatSnackBarRef<SnackbarComponent> {
    return this.showSnackbar({
      ...data,
      type: SnackbarType.INFO,
    }, {
      duration: 5000,
      ...options,
    });
  }

  /**
   * Opens a snackbar of type successs.
   */
  public showSuccessSnackbar(
    data: SnackbarData,
    options?: Partial<SnackbarOptions>,
  ): MatSnackBarRef<SnackbarComponent> {
    return this.showSnackbar({
      ...data,
      type: SnackbarType.SUCCESS,
    }, {
      duration: 5000,
      ...options,
    });
  }

  /**
   * Opens a snackbar of type error.
   */
  public showErrorSnackbar(
    data: SnackbarData,
    options?: SnackbarOptions,
  ): MatSnackBarRef<SnackbarComponent> {
    return this.showSnackbar({
      ...data,
      type: SnackbarType.ERROR,
    }, options);
  }

  public showValidationErrorSnackbar(
    data: SnackbarData = {
      text: 'The form contains validation errors. Please fix all errors before submitting the form.',
    },
    options: Partial<SnackbarOptions> | undefined = undefined,
  ): MatSnackBarRef<SnackbarComponent> {
    return this.showSnackbar({
      ...data,
      type: SnackbarType.ERROR,
    }, {
      duration: 7500,
      ...options,
    });
  }

  /**
   * Opens a configurable snackbar with the procure default options.
   */
  private showSnackbar(
    data: SnackbarData,
    options?: Partial<SnackbarOptions>,
  ): MatSnackBarRef<SnackbarComponent> {
    const mergedOptions = {
      ...this.defaultOptions,
      ...options,
    };

    return this.snackbar.openFromComponent<SnackbarComponent>(SnackbarComponent, {
      data,
      ...mergedOptions,
    });
  }
}
