import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpApi } from './http-api';
import { ENVIRONMENT_CONFIG, EnvironmentConfig } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService extends HttpApi {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environmentConfig: EnvironmentConfig,
    private _httpClient: HttpClient,
  ) {
    super(_httpClient);
  }

  /**
   * @inheritdoc
   */
  protected getEndpointUrl(url: string): string {
    return this.environmentConfig.apiEndpoint + url;
  }
}
