import { Directive, HostBinding, Input } from '@angular/core';
import { DEFAULT_LAYOUT_GAP } from '../../constants';

@Directive({
  selector: '[formRow]',
  standalone: true,
})
export class FormRowDirective {
  @HostBinding('class.form-row') public formRowClass: boolean = true;

  @Input() @HostBinding('class.form-row__border-bottom') public borderBottom: boolean = false;

  @Input() @HostBinding('class.form-row--standalone') public standalone: boolean = false;

  @Input() public formChildGap: number = DEFAULT_LAYOUT_GAP;
}
