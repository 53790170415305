import { DestroyRef, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { DialogComponent, DialogData, DialogType } from '../components/dialog';
import { DEFAULT_SMALL_DIALOG_WIDTH } from '../constants';
import { lockFormHeaderPosition, unlockFormHeaderPosition } from '../utilities/dialog.utility';

type DialogOptions = {
  readonly title?: string;
  readonly text?: string;
  readonly info?: string;
  readonly primaryAction?: string;
  readonly blackout?: boolean;
};

type OkDialogOptions = DialogOptions & {
  readonly closeCallback?: () => void;
}

type ErrorDialogOptions = DialogOptions & {
  readonly closeCallback?: () => void;
}

type ConfirmDialogOptions = DialogOptions & {
  readonly secondaryAction?: string;
  readonly confirmationCallback?: () => Observable<boolean | void>;
  readonly cancellationCallback?: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public readonly defaultOkTitle: string = 'Success';

  public readonly defaultOkText: string = 'The command was successfully executed';

  public readonly defaultErrorTitle: string = 'Error';

  public readonly defaultErrorText: string = 'An error has occurred while saving your data. Please try again. If the problem persists, please contact your administrator.';

  public readonly defaultConfirmTitle: string = 'Please Confirm';

  public readonly defaultConfirmText: string = 'Do you really want to cancel the current process? All unsaved data will be lost.';

  constructor(
    private readonly dialog: MatDialog,
    private readonly componentRef: DestroyRef,
  ) {}

  /**
   * Checks if there are any currently open dialogs.
   */
  public hasOpenDialogs(): boolean {
    const { openDialogs } = this.dialog;

    return (openDialogs != null && openDialogs.length > 0);
  }

  /**
   * Shows a basic informative dialog with an OK button.
   */
  public showOkDialog(data?: OkDialogOptions): MatDialogRef<DialogComponent> {
    lockFormHeaderPosition();

    const dialogRef = this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      closeOnNavigation: false,
      width: DEFAULT_SMALL_DIALOG_WIDTH,
      panelClass: ['dialog', 'dialog--info'],
      data: {
        ...data,
        title: data?.title || this.defaultOkTitle,
        text: data?.text || this.defaultOkText,
        type: DialogType.INFO,
      },
      backdropClass: data?.blackout ? 'cdk-overlay-backdrop--blackout' : '',
    });

    unlockFormHeaderPosition(dialogRef, this.componentRef);

    return dialogRef;
  }

  /**
   * Shows a basic error dialog with an OK button.
   */
  public showErrorDialog(data?: ErrorDialogOptions): MatDialogRef<DialogComponent> {
    lockFormHeaderPosition();

    const dialogRef = this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      closeOnNavigation: false,
      width: DEFAULT_SMALL_DIALOG_WIDTH,
      panelClass: ['dialog', 'dialog--error'],
      data: {
        ...data,
        title: data?.title || this.defaultErrorTitle,
        text: data?.text || this.defaultErrorText,
        type: DialogType.ERROR,
      },
      backdropClass: data?.blackout ? 'cdk-overlay-backdrop--blackout' : '',
    });

    unlockFormHeaderPosition(dialogRef, this.componentRef);

    return dialogRef;
  }

  /**
   * The confirmation callback will be executed once the dialog was confirmed.
   * If the confirmation callback completed, the dialog will be closed.
   */
  public showConfirmDialog(data?: ConfirmDialogOptions): MatDialogRef<DialogComponent> {
    lockFormHeaderPosition();

    const dialogRef = this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      closeOnNavigation: false,
      width: DEFAULT_SMALL_DIALOG_WIDTH,
      panelClass: ['dialog', 'dialog--prompt'],
      data: {
        ...data,
        title: data?.title || this.defaultConfirmTitle,
        text: data?.text || this.defaultConfirmText,
        type: DialogType.PROMPT,
      },
      backdropClass: data?.blackout ? 'cdk-overlay-backdrop--blackout' : '',
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.componentRef))
      .subscribe((result: boolean) => {
        // Result will be false, if confirmation was unsuccessful, or dialog was cancelled.
        if (!result && data?.cancellationCallback) {
          data.cancellationCallback();
        }
      });

    unlockFormHeaderPosition(dialogRef, this.componentRef);

    return dialogRef;
  }
}
