import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';

/**
 * Define the UploadFields and the status of the pending uploads
 */
export interface UploadFieldProgress {
  key: string;
  progress: number;
  completed: boolean;
}

@Component({
  selector: 'procure-form-header-uploads',
  templateUrl: './form-header-uploads.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatProgressBarModule,
  ],
})
export class FormHeaderUploadsComponent {
  @Input()
  public uploadFieldsProgress: readonly UploadFieldProgress[] = [];

  /**
   * Update or add a existing or new Array element.
   */
  public static updateUploadsArray(
    arrayElem: UploadFieldProgress,
    existingUploads: readonly UploadFieldProgress[],
  ): readonly UploadFieldProgress[] {
    const result = [...existingUploads];
    const selectEntry = result.find((elem) => elem.key === arrayElem.key);

    if (selectEntry) {
      selectEntry.completed = arrayElem.completed;
      selectEntry.progress = arrayElem.progress;
      selectEntry.key = arrayElem.key;
    }

    return result;
  }

  public uploadFieldsTrackByFn(index: number, field: UploadFieldProgress): string {
    return field ? field.key : '';
  }
}
