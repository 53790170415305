import { coerceBoolean } from 'coerce-property';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  HostBinding,
  NgZone,
  Attribute,
} from '@angular/core';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { MatButton } from '@angular/material/button';
import { ThemePalette, MatRippleModule } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

/**
 * Material design Procure button.
 */
@Component({
  selector: `button[procure-button],
             button[procure-icon-button],
             button[procure-stroked-button],
             button[procure-sharp-edge-button],
             button[procure-flat-button]`,
  exportAs: 'procureButton',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRippleModule,
  ],
})
export class ButtonComponent extends MatButton {
  public static nextId: number = 0;

  /** Whether the button displays a loading indicator. */
  @Input() @coerceBoolean public loading: boolean = false;

  @HostBinding('class.procure-button')
  public readonly procureButtonClass: boolean = true;

  @HostBinding()
  @Input()
  public id: string;

  @Input()
  public disabled: boolean = false;

  @Input()
  public color: ThemePalette;

  @Input()
  public prefix: string | null = null;

  @Input()
  public suffix: string | null = null;

  public isIconButton: boolean = false;

  private _class: string = 'procure-button';

  @HostBinding('class')
  public get class(): string { return `procure-button ${this.color ? `mat-${this.color}` : ''} ${this._class}`; }

  public set class(value: string) {
    this._class = value;
  }

  @HostBinding('attr.disabled')
  public get disabledAttribute(): boolean | null { return this.disabled || null; }

  @HostBinding('class._mat-animation-noopable')
  public get noopableClass(): boolean { return this._animationMode === 'NoopAnimations'; }

  @HostBinding('class.procure-icon-button')
  public get procureIconButtonClass(): boolean { return this.procureIconButton != null; }

  @HostBinding('class.procure-stroked-button')
  public get procureStrokedButtonClass(): boolean { return this.procureStrokedButton != null; }

  @HostBinding('class.procure-flat-button')
  public get procureFlatButtonClass(): boolean { return this.procureFlatButton != null; }

  @HostBinding('class.procure-sharp-edge-button')
  public get procureSharpEdgeButtonClass(): boolean { return this.procureSharpEdgeButton != null; }

  constructor(
    public elementRef: ElementRef,
    _platform: Platform,
    _ngZone: NgZone,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) _animationMode: string,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Optional() @Attribute('procure-button') protected procureButton: string | null | undefined,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Optional() @Attribute('procure-icon-button') protected procureIconButton: string | null | undefined,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Optional() @Attribute('procure-stroked-button') protected procureStrokedButton: string | null | undefined,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Optional() @Attribute('procure-flat-button') protected procureFlatButton: string | null | undefined,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Optional() @Attribute('procure-sharp-edge-button') protected procureSharpEdgeButton: string | null | undefined,
  ) {
    super(elementRef, _platform, _ngZone, _animationMode);

    this.id = `procure-button-${ButtonComponent.nextId}`;
    ButtonComponent.nextId += 1;
  }
}
