/**
 * Prefixes for all the top-level entity ID aliases.
 */
export enum EntityIdAliasPrefixes {
  PriceEntry = 'PE',
  Project = 'PROJ',
  ProjectExpediting = 'PEX',
  PurchaseRequisition = 'PREQ',
  Rfq = 'RFQ',
  RfRFQ = 'RFRFQ',
  Supplier = 'SUP',
  Quotation = 'QUOT',
  TemplateProject = 'TPROJ',
  TemplateRrfrfq = 'TRFRFQ',
}

const entityNames = new Map<EntityIdAliasPrefixes, string>([
  [EntityIdAliasPrefixes.PriceEntry, 'Price Entry'],
  [EntityIdAliasPrefixes.Project, 'Project'],
  [EntityIdAliasPrefixes.ProjectExpediting, 'Project Expediting'],
  [EntityIdAliasPrefixes.PurchaseRequisition, 'Purchase Requisition'],
  [EntityIdAliasPrefixes.Rfq, 'RFQ'],
  [EntityIdAliasPrefixes.RfRFQ, 'RfRFQ'],
  [EntityIdAliasPrefixes.Supplier, 'Supplier'],
  [EntityIdAliasPrefixes.Quotation, 'Quotation'],
  [EntityIdAliasPrefixes.TemplateProject, 'Template Project'],
  [EntityIdAliasPrefixes.TemplateRrfrfq, 'Template RfRFQ'],
]);

export class EntityUtility {
  public static getEntityName = (
    entity: EntityIdAliasPrefixes,
    plural: boolean = false,
  ): string | null => `${entityNames.get(entity)}${plural ? 's' : ''}` || null;

  /**
   * Get the type of an entity based on its entity number.
   */
  public static getType(entityNumber: string, seperator: string = '-'): EntityIdAliasPrefixes | null {
    const splits: string[] = entityNumber.split(seperator);

    if (!splits.length) {
      return null;
    }

    if (
      Object.values(EntityIdAliasPrefixes)
        .includes(splits[0].toUpperCase() as EntityIdAliasPrefixes)
    ) {
      return splits[0].toUpperCase() as EntityIdAliasPrefixes;
    }

    return null;
  }

  /**
   * Get the show / edit link to an entity based on its entity number.
   */
  public static getLink(entityNumber: string): string | null {
    const entity = EntityUtility.getType(entityNumber);

    if (!entity) {
      return null;
    }

    if (entity === EntityIdAliasPrefixes.Supplier) {
      // @TODO: this is ugly and we should talk about it
      // entityNumber has to be the SUP-ID id beeing the real ID like 666
      return `/Supplier/Show/${EntityUtility.getNumber(entityNumber)}`;
    }

    return `/${entityNumber}`;
  }

  /**
   * Get the actual number of an entity based on its entity number.
   */
  public static getNumber(entityNumber: string): number | null {
    const splits: string[] = entityNumber.split('-');

    if (!splits.length || Number.isNaN(Number(splits[1])) || !Number.isInteger(Number(splits[1]))) {
      return null;
    }

    return Number(splits[1]);
  }
}
