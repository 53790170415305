import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export type BrowserInfo = Record<string, boolean> & {
  name?: string;
  version?: string;
  versionNumber?: number;
  platform?: string;
};

@Injectable({
  providedIn: 'root',
})
export class BrowserDetectionService {
  private readonly browserInfo: BrowserInfo;

  private readonly _isPlatformBrowser: boolean;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: Object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const userAgent = window.navigator?.userAgent || '';
      const matched = this.matchUserAgent(userAgent);
      const browser: BrowserInfo = {};

      if (matched.browser) {
        browser[matched.browser] = true;
        browser.version = matched.version;
        browser.versionNumber = parseInt(matched.versionNumber, 10);
      }

      if (matched.platform) {
        browser[matched.platform] = true;
      }

      if (browser.opr) {
        matched.browser = 'opera';
        browser.opera = true;
      }

      if (browser.rv) {
        matched.browser = 'msie';
        browser.msie = true;
      }

      if (browser.edge) {
        matched.browser = 'edge';
        browser.edge = true;
      }

      if (browser.safari && browser.android) {
        matched.browser = 'android';
        browser.android = true;
      }

      browser.name = matched.browser;
      browser.platform = matched.platform;

      this.browserInfo = browser;
      this._isPlatformBrowser = true;
    } else {
      this.browserInfo = {};
      this._isPlatformBrowser = false;
    }
  }

  /**
   * Checkes if user agent is either Chome, Edge or Chromium-based Edge browser.
   */
  public get isRecommendedBrowser(): boolean {
    return this.browserInfo?.name === 'chrome' || this.browserInfo?.name === 'edge';
  }

  /**
   * Returns whether the platform represents a browser platform.
   */
  public isPlatformBrowser(): boolean {
    return this._isPlatformBrowser;
  }

  /**
   * Returns the name of the browser, if any
   */
  public getName(): string {
    return this.browserInfo.name || '';
  }

  /**
   * Returns the name of the browser platform, e.g. windows
   */
  public getPlatform(): string {
    return (this.browserInfo.platform === 'win' ? 'windows' : this.browserInfo.platform || '');
  }

  /**
   * Returns the browser version string
   */
  public getVersion(): string | undefined {
    return this.browserInfo.version;
  }

  /**
   * Returns the browser version number
   */
  public getVersionNumber(): number | undefined {
    return this.browserInfo.versionNumber;
  }

  /**
   * Returns whether the browser is an old IE browser (<=11)
   */
  public isIE(): boolean {
    return this.browserInfo.msie || false;
  }

  /**
   * Returns whether the browser is an IE Edge browser
   */
  public isEdge(): boolean {
    return this.browserInfo.edge || false;
  }

  /**
   * Returns whether the browser is an old IE (<=11) or IE Edge browser
   */
  public isIEOrEdge(): boolean {
    return this.isIE() || this.isEdge();
  }

  /**
   * Returns whether the browser is used on a mobile device
   */
  public isMobile(): boolean {
    return (this.browserInfo.android || this.browserInfo.ipad || this.browserInfo.iphone || this.browserInfo['windows phone'] || false);
  }

  /**
   * Returns whether the browser is used on a desktop device
   */
  public isDesktop(): boolean {
    return (this.browserInfo.cros
      || this.browserInfo.mac
      || this.browserInfo.linux
      || this.browserInfo.win
      || false);
  }

  /**
   * Returns whether the browser uses the WebKit rendering engine
   */
  public isWebKit(): boolean {
    return (this.browserInfo.chrome || this.browserInfo.opr || this.browserInfo.safari || false);
  }

  // eslint-disable-next-line complexity
  private matchUserAgent(
    userAgent: string,
  ): { browser: string; version: string; versionNumber: string; platform: string } {
    const ua = userAgent.toLowerCase();

    const match = /(edge)\/([\w.]+)/.exec(ua)
      || /(opr)[/]([\w.]+)/.exec(ua)
      || /(chrome)[/]([\w.]+)/.exec(ua)
      || /(version)(applewebkit)[/]([\w.]+).*(safari)[/]([\w.]+)/.exec(ua)
      || /(webkit)[/]([\w.]+).*(version)[/]([\w.]+).*(safari)[/]([\w.]+)/.exec(ua)
      || /(webkit)[/]([\w.]+)/.exec(ua)
      || /(opera)(?:.*version|)[/]([\w.]+)/.exec(ua)
      || /(msie) ([\w.]+)/.exec(ua)
      || (ua.indexOf('trident') >= 0 && /(rv)(?::| )([\w.]+)/.exec(ua))
      || (ua.indexOf('compatible') < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua))
      || [];

    const platformMatch = /(ipad)/.exec(ua)
      || /(iphone)/.exec(ua)
      || /(android)/.exec(ua)
      || /(windows phone)/.exec(ua)
      || /(win)/.exec(ua)
      || /(mac)/.exec(ua)
      || /(linux)/.exec(ua)
      || /(cros)/.exec(ua)
      || [];

    return {
      browser: match[5] || match[3] || match[1] || '',
      version: match[2] || match[4] || '0',
      versionNumber: match[4] || match[2] || '0',
      platform: platformMatch[0] || '',
    };
  }
}
