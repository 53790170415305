import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<Record<string, unknown>>,
    next: HttpHandler,
  ): Observable<HttpEvent<Record<string, unknown>>> {
    if (req.method === 'POST' || req.method === 'PUT') {
      // eslint-disable-next-line no-param-reassign
      req = req.clone({
        body: this.shiftDates(req.body),
      });
    }

    return next.handle(req);
  }

  private checkOnPureObject(obj: unknown): boolean {
    if (typeof obj !== 'object') return false;
    if (obj instanceof Date) return false;
    if (obj instanceof Function) return false;
    if (obj instanceof Array) return false;

    return true;
  }

  private shiftDates(body: Record<string, unknown> | null): Record<string, unknown> | null {
    if (body === null || body === undefined || !this.checkOnPureObject(body)) {
      return body;
    }

    const copy = { ...body };

    Object.keys(copy).forEach((key) => {
      const value = copy[key];

      if (value instanceof Date) {
        copy[key] = new Date(Date.UTC(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          value.getHours(),
          value.getMinutes(),
          value.getSeconds(),
        ));
      } else if (Array.isArray(value)) {
        copy[key] = value.map((item) => this.shiftDates(item));
      } else if (typeof value === 'object') {
        copy[key] = this.shiftDates(value as Record<string, unknown>);
      }
    });

    return copy;
  }
}
