import { Injectable, Inject } from '@angular/core';
import { BADGE_COLOR } from '../constants';
import {
  DONE_STATUS_COLOR,
  DRAFT_STATUS_COLOR,
  FAIL_STATUS_COLOR,
  HOLD_STATUS_COLOR,
  PROGRESS_STATUS_COLOR,
} from '../injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class StatusColorService {
  public colors: Record<string, string>;

  constructor(
    @Inject(DRAFT_STATUS_COLOR) public readonly draftStatusColor: string,
    @Inject(PROGRESS_STATUS_COLOR) public readonly progressStatusColor: string,
    @Inject(DONE_STATUS_COLOR) public readonly doneStatusColor: string,
    @Inject(FAIL_STATUS_COLOR) public readonly failStatusColor: string,
    @Inject(HOLD_STATUS_COLOR) public readonly holdStatusColor: string,
  ) {
    this.colors = {
      Draft: draftStatusColor,
      Closed: draftStatusColor,
      Tender: progressStatusColor,
      'In Execution': progressStatusColor,
      Requested: progressStatusColor,
      Sent: progressStatusColor,
      Received: progressStatusColor,
      Checked: progressStatusColor,
      Budgetary: progressStatusColor,
      Published: doneStatusColor,
      Selected: doneStatusColor,
      Issued: doneStatusColor,
      Firm: doneStatusColor,
      'On Hold': holdStatusColor,
      'Under Revision': holdStatusColor,
      Deleted: failStatusColor,
      Rejected: failStatusColor,
      Declined: failStatusColor,
      Expired: failStatusColor,
    };
  }

  public getStatusColor(label: string): string {
    return this.colors[label] || BADGE_COLOR;
  }
}
