import { coerceBoolean } from 'coerce-property';
import {
  Component, Input, HostBinding, ChangeDetectionStrategy,
} from '@angular/core';
import { BADGE_COLOR } from '../../constants';
import { StatusColorService } from '../../services/status-color.service';
import { BadgeComponent } from '../badge/badge.component';

@Component({
  selector: 'procure-status-badge',
  templateUrl: './status-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BadgeComponent],
})
export class StatusBadgeComponent {
  @HostBinding('class')
  @Input()
  public hostClass: string = '';

  @Input()
  public label: string | null = null;

  @Input() @coerceBoolean public inverted: boolean | '' = false;

  public get statusColor(): string {
    return this.label ? this.statusColorService.getStatusColor(this.label) : BADGE_COLOR;
  }

  constructor(
    private readonly statusColorService: StatusColorService,
  ) {}
}
